import React from "react";
import {
  Container,
  GridContainer,
  Heading,
  ValidatorColumn,
  ValidatorImg,
  ValidatorName,
} from "./Validators.styles";
import { Grid } from "base-components";
import { getFormattedNumber } from "utils/math";
import { GridColumn } from "base-components/Grid/Grid.types";
import { ClientStore } from "stores";
import { observer } from "mobx-react";

const data = [
  {
    name: "OKXEarn",
    logo: "",
    staked: 6500000,
    apy: 3.25,
    commission: 0,
  },
  {
    name: "Triton One",
    logo: "",
    staked: 362832,
    apy: 3.19,
    commission: 3,
  },
  {
    name: "Blockscope.net",
    logo: "",
    staked: 1160000,
    apy: 3.19,
    commission: 4,
  },
  {
    name: "OKXEarn",
    logo: "",
    staked: 6500000,
    apy: 3.25,
    commission: 0,
  },
  {
    name: "Triton One",
    logo: "",
    staked: 362832,
    apy: 3.19,
    commission: 3,
  },
  {
    name: "Blockscope.net",
    logo: "",
    staked: 1160000,
    apy: 3.19,
    commission: 4,
  },
  {
    name: "OKXEarn",
    logo: "",
    staked: 6500000,
    apy: 3.25,
    commission: 0,
  },
  {
    name: "Triton One",
    logo: "",
    staked: 362832,
    apy: 3.19,
    commission: 3,
  },
  {
    name: "Blockscope.net",
    logo: "",
    staked: 1160000,
    apy: 3.19,
    commission: 4,
  },
];

const columns: GridColumn[] = [
  {
    name: "Validators",
    accessor: "name",
    renderer: (columnValue, { imageUrl }) => (
      <ValidatorColumn>
        <ValidatorImg src={imageUrl} />
        <ValidatorName>{columnValue}</ValidatorName>
      </ValidatorColumn>
    ),
    flex: 3,
    headerAlignment: Grid.ALIGNMENTS.LEFT,
  },
  {
    name: "Staked (SUI)",
    accessor: "stakingPoolSuiBalance",
    renderer: (columnValue) =>
      getFormattedNumber(Number(columnValue) / Math.pow(10, 9), 0, 2),
    flex: 1,
    alignment: Grid.ALIGNMENTS.CENTER,
  },
  {
    name: "APY",
    accessor: "apy",
    renderer: (columnValue) => `${getFormattedNumber(columnValue, 0, 2)}%`,
    flex: 1,
    alignment: Grid.ALIGNMENTS.CENTER,
  },
  {
    name: "Commission",
    accessor: "commissionRate",
    renderer: (columnValue) =>
      `${getFormattedNumber(Number(columnValue) / 100, 0, 0)}%`,
    flex: 1,
    alignment: Grid.ALIGNMENTS.CENTER,
    headerAlignment: Grid.ALIGNMENTS.CENTER,
  },
];

function Validators() {
  const { validators } = ClientStore;

  return (
    <Container>
      <Heading>Validators</Heading>
      <GridContainer>
        <Grid data={validators} columns={columns} />
      </GridContainer>
    </Container>
  );
}

export default observer(Validators);
