import React from 'react';
import { IconProps } from 'base-components/Icon';

export function RewindIcon(props: IconProps) {
  const { fill = 'var(--grey-100)', size = '24', ...rest } = props;
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...rest}
    >
      <rect
        x='2'
        y='8'
        width='2'
        height='2'
        transform='rotate(90 2 8)'
        fill={fill}
      />
      <rect
        x='5'
        y='2'
        width='2'
        height='2'
        transform='rotate(90 5 2)'
        fill={fill}
      />
      <rect
        x='4'
        y='1'
        width='2'
        height='2'
        transform='rotate(90 4 1)'
        fill={fill}
      />
      <rect
        x='7'
        y='4'
        width='2'
        height='2'
        transform='rotate(90 7 4)'
        fill={fill}
      />
      <rect
        x='6'
        y='3'
        width='2'
        height='2'
        transform='rotate(90 6 3)'
        fill={fill}
      />
      <rect
        x='9'
        y='17'
        width='2'
        height='2'
        transform='rotate(90 9 17)'
        fill={fill}
      />
      <rect
        x='7'
        y='16'
        width='2'
        height='2'
        transform='rotate(90 7 16)'
        fill={fill}
      />
      <rect
        x='5'
        y='15'
        width='2'
        height='2'
        transform='rotate(90 5 15)'
        fill={fill}
      />
      <rect
        x='12'
        y='11'
        width='2'
        height='2'
        transform='rotate(90 12 11)'
        fill={fill}
      />
      <rect
        x='13'
        y='11'
        width='2'
        height='2'
        transform='rotate(90 13 11)'
        fill={fill}
      />
      <rect
        x='14'
        y='11'
        width='2'
        height='2'
        transform='rotate(90 14 11)'
        fill={fill}
      />
      <rect
        x='13'
        y='10'
        width='2'
        height='2'
        transform='rotate(90 13 10)'
        fill={fill}
      />
      <rect
        x='13'
        y='10'
        width='2'
        height='2'
        transform='rotate(90 13 10)'
        fill={fill}
      />
      <rect
        x='5'
        y='16'
        width='2'
        height='2'
        transform='rotate(90 5 16)'
        fill={fill}
      />
      <rect
        x='5'
        y='18'
        width='2'
        height='2'
        transform='rotate(90 5 18)'
        fill={fill}
      />
      <rect
        x='7'
        y='20'
        width='2'
        height='2'
        transform='rotate(90 7 20)'
        fill={fill}
      />
      <rect
        x='9'
        y='22'
        width='2'
        height='2'
        transform='rotate(90 9 22)'
        fill={fill}
      />
      <rect
        x='11'
        y='22'
        width='2'
        height='2'
        transform='rotate(90 11 22)'
        fill={fill}
      />
      <rect
        x='13'
        y='22'
        width='2'
        height='2'
        transform='rotate(90 13 22)'
        fill={fill}
      />
      <rect
        x='18'
        y='8'
        width='2'
        height='2'
        transform='rotate(90 18 8)'
        fill={fill}
      />
      <rect
        x='17'
        y='6'
        width='2'
        height='2'
        transform='rotate(90 17 6)'
        fill={fill}
      />
      <rect
        x='15'
        y='5'
        width='2'
        height='2'
        transform='rotate(90 15 5)'
        fill={fill}
      />
      <rect
        x='6'
        y='11'
        width='2'
        height='2'
        transform='rotate(90 6 11)'
        fill={fill}
      />
      <rect
        x='11'
        y='7'
        width='2'
        height='2'
        transform='rotate(90 11 7)'
        fill={fill}
      />
      <rect
        x='6'
        y='2'
        width='2'
        height='2'
        transform='rotate(90 6 2)'
        fill={fill}
      />
      <rect
        x='7'
        y='3'
        width='2'
        height='2'
        transform='rotate(90 7 3)'
        fill={fill}
      />
      <rect
        x='4'
        y='1'
        width='2'
        height='2'
        transform='rotate(90 4 1)'
        fill={fill}
      />
      <rect
        x='12'
        y='9'
        width='2'
        height='2'
        transform='rotate(90 12 9)'
        fill={fill}
      />
      <rect
        x='2'
        y='2'
        width='2'
        height='2'
        transform='rotate(90 2 2)'
        fill={fill}
      />
      <rect
        x='2'
        y='1'
        width='2'
        height='2'
        transform='rotate(90 2 1)'
        fill={fill}
      />
      <rect
        x='2'
        width='2'
        height='2'
        transform='rotate(90 2 0)'
        fill={fill}
      />
      <rect
        x='9'
        y='2'
        width='2'
        height='2'
        transform='rotate(90 9 2)'
        fill={fill}
      />
      <rect
        x='7'
        y='3'
        width='2'
        height='2'
        transform='rotate(90 7 3)'
        fill={fill}
      />
      <rect
        x='8'
        y='3'
        width='2'
        height='2'
        transform='rotate(90 8 3)'
        fill={fill}
      />
      <rect
        x='13'
        y='1'
        width='2'
        height='2'
        transform='rotate(90 13 1)'
        fill={fill}
      />
      <rect
        x='12'
        y='5'
        width='2'
        height='2'
        transform='rotate(90 12 5)'
        fill={fill}
      />
      <rect
        x='11'
        y='7'
        width='2'
        height='2'
        transform='rotate(90 11 7)'
        fill={fill}
      />
      <rect
        x='11'
        y='8'
        width='2'
        height='2'
        transform='rotate(90 11 8)'
        fill={fill}
      />
      <rect
        x='11'
        y='7'
        width='2'
        height='2'
        transform='rotate(90 11 7)'
        fill={fill}
      />
      <rect
        x='12'
        y='8'
        width='2'
        height='2'
        transform='rotate(90 12 8)'
        fill={fill}
      />
      <rect
        x='10'
        y='11'
        width='2'
        height='2'
        transform='rotate(90 10 11)'
        fill={fill}
      />
      <rect
        x='8'
        y='11'
        width='2'
        height='2'
        transform='rotate(90 8 11)'
        fill={fill}
      />
      <rect
        x='4'
        y='11'
        width='2'
        height='2'
        transform='rotate(90 4 11)'
        fill={fill}
      />
      <rect
        x='16'
        y='1'
        width='2'
        height='2'
        transform='rotate(90 16 1)'
        fill={fill}
      />
      <rect
        x='13'
        y='5'
        width='2'
        height='2'
        transform='rotate(90 13 5)'
        fill={fill}
      />
      <rect
        x='23'
        y='9'
        width='2'
        height='2'
        transform='rotate(90 23 9)'
        fill={fill}
      />
      <rect
        x='20'
        y='3'
        width='2'
        height='2'
        transform='rotate(90 20 3)'
        fill={fill}
      />
      <rect
        x='18'
        y='2'
        width='2'
        height='2'
        transform='rotate(90 18 2)'
        fill={fill}
      />
      <rect
        x='22'
        y='7'
        width='2'
        height='2'
        transform='rotate(90 22 7)'
        fill={fill}
      />
      <rect
        x='21'
        y='5'
        width='2'
        height='2'
        transform='rotate(90 21 5)'
        fill={fill}
      />
      <rect
        x='22'
        y='5'
        width='2'
        height='2'
        transform='rotate(90 22 5)'
        fill={fill}
      />
      <rect
        x='23'
        y='11'
        width='2'
        height='2'
        transform='rotate(90 23 11)'
        fill={fill}
      />
      <rect
        x='23'
        y='12'
        width='2'
        height='2'
        transform='rotate(90 23 12)'
        fill={fill}
      />
      <rect
        x='23'
        y='14'
        width='2'
        height='2'
        transform='rotate(90 23 14)'
        fill={fill}
      />
      <rect
        x='22'
        y='16'
        width='2'
        height='2'
        transform='rotate(90 22 16)'
        fill={fill}
      />
      <rect
        x='21'
        y='18'
        width='2'
        height='2'
        transform='rotate(90 21 18)'
        fill={fill}
      />
      <rect
        x='22'
        y='18'
        width='2'
        height='2'
        transform='rotate(90 22 18)'
        fill={fill}
      />
      <rect
        x='19'
        y='21'
        width='2'
        height='2'
        transform='rotate(90 19 21)'
        fill={fill}
      />
      <rect
        x='20'
        y='20'
        width='2'
        height='2'
        transform='rotate(90 20 20)'
        fill={fill}
      />
      <rect
        x='21'
        y='20'
        width='2'
        height='2'
        transform='rotate(90 21 20)'
        fill={fill}
      />
      <rect
        x='17'
        y='22'
        width='2'
        height='2'
        transform='rotate(90 17 22)'
        fill={fill}
      />
      <rect
        x='18'
        y='22'
        width='2'
        height='2'
        transform='rotate(90 18 22)'
        fill={fill}
      />
      <rect
        x='10'
        y='7'
        width='2'
        height='2'
        transform='rotate(90 10 7)'
        fill={fill}
      />
      <rect
        x='10'
        y='7'
        width='2'
        height='2'
        transform='rotate(90 10 7)'
        fill={fill}
      />
      <rect
        x='11'
        y='6'
        width='2'
        height='2'
        transform='rotate(90 11 6)'
        fill={fill}
      />
      <rect
        x='13'
        y='18'
        width='2'
        height='2'
        transform='rotate(90 13 18)'
        fill={fill}
      />
      <rect
        x='11'
        y='18'
        width='2'
        height='2'
        transform='rotate(90 11 18)'
        fill={fill}
      />
      <rect
        x='19'
        y='10'
        width='2'
        height='2'
        transform='rotate(90 19 10)'
        fill={fill}
      />
      <rect
        x='19'
        y='12'
        width='2'
        height='2'
        transform='rotate(90 19 12)'
        fill={fill}
      />
      <rect
        x='19'
        y='12'
        width='2'
        height='2'
        transform='rotate(90 19 12)'
        fill={fill}
      />
      <rect
        x='19'
        y='13'
        width='2'
        height='2'
        transform='rotate(90 19 13)'
        fill={fill}
      />
      <rect
        x='18'
        y='15'
        width='2'
        height='2'
        transform='rotate(90 18 15)'
        fill={fill}
      />
      <rect
        x='17'
        y='17'
        width='2'
        height='2'
        transform='rotate(90 17 17)'
        fill={fill}
      />
      <rect
        x='15'
        y='18'
        width='2'
        height='2'
        transform='rotate(90 15 18)'
        fill={fill}
      />
      <rect
        x='15'
        y='22'
        width='2'
        height='2'
        transform='rotate(90 15 22)'
        fill={fill}
      />
      <rect
        x='2'
        y='10'
        width='2'
        height='2'
        transform='rotate(90 2 10)'
        fill={fill}
      />
      <rect
        x='2'
        y='11'
        width='2'
        height='2'
        transform='rotate(90 2 11)'
        fill={fill}
      />
      <rect
        x='2'
        y='6'
        width='2'
        height='2'
        transform='rotate(90 2 6)'
        fill={fill}
      />
      <rect
        x='2'
        y='4'
        width='2'
        height='2'
        transform='rotate(90 2 4)'
        fill={fill}
      />
      <rect
        x='15'
        y='1'
        width='2'
        height='2'
        transform='rotate(90 15 1)'
        fill={fill}
      />
      <rect
        x='11'
        y='1'
        width='2'
        height='2'
        transform='rotate(90 11 1)'
        fill={fill}
      />
      <rect
        x='24'
        y='9'
        width='2'
        height='2'
        transform='rotate(90 24 9)'
        fill={fill}
      />
      <rect
        x='24'
        y='8'
        width='2'
        height='2'
        transform='rotate(90 24 8)'
        fill={fill}
      />
      <rect
        x='23'
        y='7'
        width='2'
        height='2'
        transform='rotate(90 23 7)'
        fill={fill}
      />
      <rect
        x='24'
        y='11'
        width='2'
        height='2'
        transform='rotate(90 24 11)'
        fill={fill}
      />
      <rect
        x='24'
        y='12'
        width='2'
        height='2'
        transform='rotate(90 24 12)'
        fill={fill}
      />
      <rect
        x='24'
        y='14'
        width='2'
        height='2'
        transform='rotate(90 24 14)'
        fill={fill}
      />
      <rect
        x='24'
        y='15'
        width='2'
        height='2'
        transform='rotate(90 24 15)'
        fill={fill}
      />
      <rect
        x='23'
        y='16'
        width='2'
        height='2'
        transform='rotate(90 23 16)'
        fill={fill}
      />
      <rect
        x='23'
        y='17'
        width='2'
        height='2'
        transform='rotate(90 23 17)'
        fill={fill}
      />
    </svg>
  );
}
