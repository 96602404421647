import { Transaction, Inputs } from "@mysten/sui/transactions";

export function wrap(txb: Transaction, coin: any) {
  if (typeof coin == 'string') {
    return txb.object(coin);
  }

  if ('digest' in coin && 'version' in coin && 'objectId' in coin) {
    return txb.objectRef(coin);
  }

  if ('objectId' in coin && 'initialSharedVersion' in coin && 'mutable' in coin) {
    return txb.sharedObjectRef(coin);
  }

  if ('Object' in coin) {
    if ('ImmOrOwned' in coin.Object) {
      return txb.object(Inputs.ObjectRef(coin.Object.ImmOrOwned));
    }
    if ('Shared' in coin.Object) {
      return txb.object(Inputs.SharedObjectRef(coin.Object.Shared));
    }

    throw new Error('Invalid argument type')
  }

  if ('kind' in coin)
    return coin;

  throw new Error('Unknown type');
}
