import React from 'react';
import { IconProps } from 'base-components/Icon';

export function ChevronIcon(props: IconProps) {
  const { fill = '#1A1A1A', size = '24', ...rest } = props;
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...rest}
    >
      <path
        d='M12 13.6727L16.95 8.72266L18.364 10.1367L12 16.5007L5.63599 10.1367L7.04999 8.72266L12 13.6727Z'
        fill={fill}
      />
    </svg>
  );
}
