import React from 'react';

export function UnstakeIcon(props: React.SVGAttributes<SVGElement>) {
  const { fill, ...rest } = props;
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...rest}
    >
      <g clip-path='url(#clip0_1170_4983)'>
        <rect
          x='1'
          y='11'
          width='2'
          height='2'
          fill='#1A1A1A'
        />
        <rect
          y='11'
          width='2'
          height='2'
          fill='#1A1A1A'
        />
        <rect
          x='3'
          y='11'
          width='2'
          height='2'
          fill='#1A1A1A'
        />
        <rect
          x='5'
          y='11'
          width='2'
          height='2'
          fill='#1A1A1A'
        />
        <rect
          x='5'
          y='9'
          width='2'
          height='2'
          fill='#1A1A1A'
        />
        <rect
          x='5'
          y='8'
          width='2'
          height='2'
          fill='#1A1A1A'
        />
        <rect
          x='5'
          y='13'
          width='2'
          height='2'
          fill='#1A1A1A'
        />
        <rect
          x='5'
          y='14'
          width='2'
          height='2'
          fill='#1A1A1A'
        />
        <rect
          x='7'
          y='8'
          width='2'
          height='2'
          fill='#1A1A1A'
        />
        <rect
          x='7'
          y='10'
          width='2'
          height='2'
          fill='#1A1A1A'
        />
        <rect
          x='7'
          y='12'
          width='2'
          height='2'
          fill='#1A1A1A'
        />
        <rect
          x='7'
          y='14'
          width='2'
          height='2'
          fill='#1A1A1A'
        />
        <rect
          x='7'
          y='6'
          width='2'
          height='2'
          fill='#1A1A1A'
        />
        <rect
          x='9'
          y='5'
          width='2'
          height='2'
          fill='#1A1A1A'
        />
        <rect
          x='9'
          y='17'
          width='2'
          height='2'
          fill='#1A1A1A'
        />
        <rect
          x='7'
          y='16'
          width='2'
          height='2'
          fill='#1A1A1A'
        />
        <rect
          x='11'
          y='5'
          width='2'
          height='2'
          fill='#1A1A1A'
        />
        <rect
          x='13'
          y='5'
          width='2'
          height='2'
          fill='#1A1A1A'
        />
        <rect
          x='19'
          y='7'
          width='2'
          height='2'
          fill='#1A1A1A'
        />
        <rect
          x='21'
          y='7'
          width='2'
          height='2'
          fill='#1A1A1A'
        />
        <rect
          x='22'
          y='7'
          width='2'
          height='2'
          fill='#1A1A1A'
        />
        <rect
          x='19'
          y='15'
          width='2'
          height='2'
          fill='#1A1A1A'
        />
        <rect
          x='21'
          y='15'
          width='2'
          height='2'
          fill='#1A1A1A'
        />
        <rect
          x='22'
          y='15'
          width='2'
          height='2'
          fill='#1A1A1A'
        />
        <rect
          x='15'
          y='5'
          width='2'
          height='2'
          fill='#1A1A1A'
        />
        <rect
          x='16'
          y='5'
          width='2'
          height='2'
          fill='#1A1A1A'
        />
        <rect
          x='11'
          y='17'
          width='2'
          height='2'
          fill='#1A1A1A'
        />
        <rect
          x='13'
          y='17'
          width='2'
          height='2'
          fill='#1A1A1A'
        />
        <rect
          x='15'
          y='17'
          width='2'
          height='2'
          fill='#1A1A1A'
        />
        <rect
          x='16'
          y='17'
          width='2'
          height='2'
          fill='#1A1A1A'
        />
        <rect
          x='18'
          y='3'
          width='2'
          height='2'
          fill='#1A1A1A'
        />
        <rect
          x='18'
          y='5'
          width='2'
          height='2'
          fill='#1A1A1A'
        />
        <rect
          x='18'
          y='7'
          width='2'
          height='2'
          fill='#1A1A1A'
        />
        <rect
          x='18'
          y='9'
          width='2'
          height='2'
          fill='#1A1A1A'
        />
        <rect
          x='18'
          y='11'
          width='2'
          height='2'
          fill='#1A1A1A'
        />
        <rect
          x='18'
          y='13'
          width='2'
          height='2'
          fill='#1A1A1A'
        />
        <rect
          x='18'
          y='15'
          width='2'
          height='2'
          fill='#1A1A1A'
        />
        <rect
          x='18'
          y='17'
          width='2'
          height='2'
          fill='#1A1A1A'
        />
        <rect
          x='18'
          y='19'
          width='2'
          height='2'
          fill='#1A1A1A'
        />
        <path
          d='M11 11.9C11 11.6791 11.1791 11.5 11.4 11.5H15.6C15.8209 11.5 16 11.6791 16 11.9V11.9C16 12.1209 15.8209 12.3 15.6 12.3H11.4C11.1791 12.3 11 12.1209 11 11.9V11.9Z'
          fill='#1A1A1A'
          stroke='black'
        />
      </g>
      <defs>
        <clipPath id='clip0_1170_4983'>
          <rect
            width='24'
            height='24'
            fill='white'
          />
        </clipPath>
      </defs>
    </svg>
  );
}
