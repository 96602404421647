import styled from '@emotion/styled';
import { ToastContainer } from 'react-toastify';

export const StyledToastContainer = styled(ToastContainer)`
  &&&.Toastify__toast-container {
    min-width: 400px;
  }
  .Toastify__toast {
    background-color: var(--beige-00);
    color: var(--grey-100);
    box-shadow: none;
    border-radius: 12px;
    border: 2px solid var(--grey-100);
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    overflow: hidden;
    padding: 0;
    min-width: 400px;
  }
  .Toastify__toast-body {
    padding: 0;
  }
`;

export const ToastContentContainer = styled.div`
  display: flex;
  align-items: stretch;
`;

export const ToastImageContainer = styled.div`
  background-color: var(--beige-20);
  padding: 16px 0;
  display: flex;
  align-items: center;
`;

export const ToastImage = styled.img`
  height: 120px;
  width: 120px;
`;

export const ToastContent = styled.div`
  position: relative;
  padding: 16px 56px 16px 16px;
  flex: 1;
`;

export const ToastContentTitle = styled.div`
  font-size: 18px;
  line-height: 150%;
  font-weight: 600;
  margin-bottom: 4px;
`;

export const ToastContentMsg = styled.div`
  margin-bottom: 4px;
`;

export const ToastContentLink = styled.a`
  color: var(--beige-100);
  font-weight: 400;
  text-decoration: underline;
`;

export const CloseBtn = styled.div`
  cursor: pointer;
  position: absolute;
  top: 16px;
  right: 16px;
`;
