export function getAllValidators(suiSystemState) {
  return suiSystemState?.activeValidators;
}

export function getWhitelistedValidators(stateData) {
  return stateData?.data?.content?.fields?.value?.fields?.whitelisted_validators?.fields?.contents || [];
}

export function getWhitelistedValidator(stateData) {
  return stateData?.data?.content?.fields?.value?.fields?.whitelisted_validators?.fields?.contents?.[0];
}