import { observable, makeObservable, action } from 'mobx';

export enum RPCNetworks {
  Suiscan = 'Suiscan',
  BlockVision = 'BlockVision',
  SuiOfficial = 'Sui Official'
}

class RPCStore {
  activeNetwork: RPCNetworks;

  constructor() {
    this.activeNetwork = RPCNetworks.SuiOfficial;

    makeObservable(this, {
      activeNetwork: observable,
      setActiveNetwork: action.bound,
    });
  }

  setActiveNetwork(network: RPCNetworks) {
    this.activeNetwork = network;
  }
}

const rpcStore = new RPCStore();

export default rpcStore;
