import { ReactNode } from 'react';

export enum GRID_ALIGNMENTS {
  LEFT = 'LEFT',
  CENTER = 'CENTER',
  RIGHT = 'RIGHT',
}

export enum GRID_THEMES {
  PRIMARY = 'PRIMARY',
  DARK = 'DARK',
}

export interface GridColumn {
  name: string;
  accessor: string;
  renderer: (
    columnValue: any,
    row: any,
    rowIndex: number,
    extraProps: any
  ) => string | ReactNode;
  alignment?: GRID_ALIGNMENTS;
  headerAlignment?: GRID_ALIGNMENTS;
  flex?: number | string;
}

export interface GridProps {
  data: any[];
  columns: GridColumn[];
  onRefresh?: () => void;
  emptyPlaceholder?: string;
  theme?: GRID_THEMES;
  onRowClick?: (row: any, index: number) => void;
  renderCustomRow?: () => ReactNode;
  extraProps?: any;
  isLoading?: boolean;
}
