export enum TOAST_TYPES {
  SUCCESS = 'success',
  ERROR = 'error',
  SUBMITTED = 'submitted',
}

export interface ToastOptions {
  txId?: string;
  title?: string;
}

export const DEFAULT_TITLES = {
  [TOAST_TYPES.SUCCESS]: 'Success',
  [TOAST_TYPES.ERROR]: 'Error',
  [TOAST_TYPES.SUBMITTED]: 'Transaction submitted',
};

export interface ToastBodyProps {
  message: string;
  type: TOAST_TYPES;
  title?: string;
  txId?: string;
}
