import React from 'react';
import { IconProps } from 'base-components/Icon';

export function ArrowRightIcon(props: IconProps) {
  const { fill = '#1A1A1A', size = '24', ...rest } = props;
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...rest}
    >
      <g clip-path='url(#clip0_2944_1219)'>
        <rect
          width='2'
          height='2'
          transform='matrix(-1 0 0 1 24.332 11)'
          fill={fill}
        />
        <rect
          width='2'
          height='2'
          transform='matrix(-1 0 0 1 22.332 13)'
          fill={fill}
        />
        <rect
          width='2'
          height='2'
          transform='matrix(-1 0 0 1 20.332 15)'
          fill={fill}
        />
        <rect
          width='2'
          height='2'
          transform='matrix(-1 0 0 1 18.332 17)'
          fill={fill}
        />
        <rect
          width='2'
          height='2'
          transform='matrix(-1 0 0 1 16.332 19)'
          fill={fill}
        />
        <rect
          width='2'
          height='2'
          transform='matrix(-1 0 0 1 14.332 19)'
          fill={fill}
        />
        <rect
          width='2'
          height='2'
          transform='matrix(-1 0 0 1 12.332 19)'
          fill={fill}
        />
        <rect
          width='2'
          height='2'
          transform='matrix(-1 0 0 1 14.332 17)'
          fill={fill}
        />
        <rect
          width='2'
          height='2'
          transform='matrix(-1 0 0 1 12.332 17)'
          fill={fill}
        />
        <rect
          width='2'
          height='2'
          transform='matrix(-1 0 0 1 14.332 15)'
          fill={fill}
        />
        <rect
          width='2'
          height='2'
          transform='matrix(-1 0 0 1 12.332 15)'
          fill={fill}
        />
        <rect
          width='2'
          height='2'
          transform='matrix(-1 0 0 1 14.332 14)'
          fill={fill}
        />
        <rect
          width='2'
          height='2'
          transform='matrix(-1 0 0 1 12.332 14)'
          fill={fill}
        />
        <rect
          width='2'
          height='2'
          transform='matrix(-1 0 0 1 10.332 14)'
          fill={fill}
        />
        <rect
          width='2'
          height='2'
          transform='matrix(-1 0 0 1 10.332 8)'
          fill={fill}
        />
        <rect
          width='2'
          height='2'
          transform='matrix(-1 0 0 1 8.33203 8)'
          fill={fill}
        />
        <rect
          width='2'
          height='2'
          transform='matrix(-1 0 0 1 6.33203 8)'
          fill={fill}
        />
        <rect
          width='2'
          height='2'
          transform='matrix(-1 0 0 1 4.33203 8)'
          fill={fill}
        />
        <rect
          width='2'
          height='2'
          transform='matrix(-1 0 0 1 8.33203 14)'
          fill={fill}
        />
        <rect
          width='2'
          height='2'
          transform='matrix(-1 0 0 1 6.33203 14)'
          fill={fill}
        />
        <rect
          width='2'
          height='2'
          transform='matrix(-1 0 0 1 4.33203 14)'
          fill={fill}
        />
        <rect
          width='2'
          height='2'
          transform='matrix(-1 0 0 1 2.33203 14)'
          fill={fill}
        />
        <rect
          width='2'
          height='2'
          transform='matrix(-1 0 0 1 2.33203 11)'
          fill={fill}
        />
        <rect
          width='2'
          height='2'
          transform='matrix(-1 0 0 1 4.33203 11)'
          fill={fill}
        />
        <rect
          width='2'
          height='2'
          transform='matrix(-1 0 0 1 2.33203 10)'
          fill={fill}
        />
        <rect
          width='2'
          height='2'
          transform='matrix(-1 0 0 1 4.33203 10)'
          fill={fill}
        />
        <rect
          width='2'
          height='2'
          transform='matrix(-1 0 0 1 2.33203 12)'
          fill={fill}
        />
        <rect
          width='2'
          height='2'
          transform='matrix(-1 0 0 1 4.33203 12)'
          fill={fill}
        />
        <rect
          width='2'
          height='2'
          transform='matrix(-1 0 0 1 2.33203 8)'
          fill={fill}
        />
        <rect
          width='2'
          height='2'
          transform='matrix(-1 0 0 1 14.332 3)'
          fill={fill}
        />
        <rect
          width='2'
          height='2'
          transform='matrix(-1 0 0 1 12.332 3)'
          fill={fill}
        />
        <rect
          width='2'
          height='2'
          transform='matrix(-1 0 0 1 14.332 5)'
          fill={fill}
        />
        <rect
          width='2'
          height='2'
          transform='matrix(-1 0 0 1 12.332 5)'
          fill={fill}
        />
        <rect
          width='2'
          height='2'
          transform='matrix(-1 0 0 1 14.332 7)'
          fill={fill}
        />
        <rect
          width='2'
          height='2'
          transform='matrix(-1 0 0 1 12.332 7)'
          fill={fill}
        />
        <rect
          width='2'
          height='2'
          transform='matrix(-1 0 0 1 12.332 5)'
          fill={fill}
        />
        <rect
          width='2'
          height='2'
          transform='matrix(-1 0 0 1 12.332 7)'
          fill={fill}
        />
        <rect
          width='2'
          height='2'
          transform='matrix(-1 0 0 1 14.332 8)'
          fill={fill}
        />
        <rect
          width='2'
          height='2'
          transform='matrix(-1 0 0 1 12.332 8)'
          fill={fill}
        />
        <rect
          width='2'
          height='2'
          transform='matrix(-1 0 0 1 22.332 9)'
          fill={fill}
        />
        <rect
          width='2'
          height='2'
          transform='matrix(-1 0 0 1 20.332 7)'
          fill={fill}
        />
        <rect
          width='2'
          height='2'
          transform='matrix(-1 0 0 1 18.332 5)'
          fill={fill}
        />
        <rect
          width='2'
          height='2'
          transform='matrix(-1 0 0 1 16.332 3)'
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id='clip0_2944_1219'>
          <rect
            width='24'
            height='24'
            fill='white'
            transform='matrix(-1 0 0 1 24.332 0)'
          />
        </clipPath>
      </defs>
    </svg>
  );
}
