export const DISPLAY_SIZES = {
  MOBILE_S: '320px',
  MOBILE_M: '375px',
  MOBILE_L: '425px',
  TABLET: '768px',
  LAPTOP: '1024px',
  LAPTOP_L: '1440px',
  DESKTOP: '1920px',
  DESKTOP_L: '2560px',
  DESKTOP_XL: '3840px',
};
