import React from 'react';
import { IconProps } from 'base-components/Icon';

export function PauseIcon(props: IconProps) {
  const { fill = 'var(--grey-100)', size = '24', ...rest } = props;
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...rest}
    >
      <path
        d='M7 21V23H9V21H7Z'
        fill={fill}
      />
      <path
        d='M5 17V19H7V17H5Z'
        fill={fill}
      />
      <path
        d='M5 21V23H7V21H5Z'
        fill={fill}
      />
      <path
        d='M5 19V21H7V19H5Z'
        fill={fill}
      />
      <path
        d='M5 15V17H7V15H5Z'
        fill={fill}
      />
      <path
        d='M7 1V3H9V1H7Z'
        fill={fill}
      />
      <path
        d='M5 3V5H7V3H5Z'
        fill={fill}
      />
      <path
        d='M5 1V3H7V1H5Z'
        fill={fill}
      />
      <path
        d='M4 3V5H6V3H4Z'
        fill={fill}
      />
      <path
        d='M4 2V4H6V2H4Z'
        fill={fill}
      />
      <path
        d='M4 5V7H6V5H4Z'
        fill={fill}
      />
      <path
        d='M4 7V9H6V7H4Z'
        fill={fill}
      />
      <path
        d='M4 15V17H6V15H4Z'
        fill={fill}
      />
      <path
        d='M4 17V19H6V17H4Z'
        fill={fill}
      />
      <path
        d='M4 20V22H6V20H4Z'
        fill={fill}
      />
      <path
        d='M4 19V21H6V19H4Z'
        fill={fill}
      />
      <path
        d='M5 9V11H7V9H5Z'
        fill={fill}
      />
      <path
        d='M4 9V11H6V9H4Z'
        fill={fill}
      />
      <path
        d='M5 13V15H7V13H5Z'
        fill={fill}
      />
      <path
        d='M4 13V15H6V13H4Z'
        fill={fill}
      />
      <path
        d='M4 11V13H6V11H4Z'
        fill={fill}
      />
      <path
        d='M5 11V13H7V11H5Z'
        fill={fill}
      />
      <path
        d='M9 2V22H11V2H9Z'
        fill={fill}
      />
      <path
        d='M5 7V9H7V7H5Z'
        fill={fill}
      />
      <path
        d='M5 5V7H7V5H5Z'
        fill={fill}
      />
      <path
        d='M16 21V23H18V21H16Z'
        fill={fill}
      />
      <path
        d='M14 17V19H16V17H14Z'
        fill={fill}
      />
      <path
        d='M14 21V23H16V21H14Z'
        fill={fill}
      />
      <path
        d='M14 19V21H16V19H14Z'
        fill={fill}
      />
      <path
        d='M14 15V17H16V15H14Z'
        fill={fill}
      />
      <path
        d='M16 1V3H18V1H16Z'
        fill={fill}
      />
      <path
        d='M14 3V5H16V3H14Z'
        fill={fill}
      />
      <path
        d='M14 1V3H16V1H14Z'
        fill={fill}
      />
      <path
        d='M13 3V5H15V3H13Z'
        fill={fill}
      />
      <path
        d='M13 2V4H15V2H13Z'
        fill={fill}
      />
      <path
        d='M13 5V7H15V5H13Z'
        fill={fill}
      />
      <path
        d='M13 7V9H15V7H13Z'
        fill={fill}
      />
      <path
        d='M13 15V17H15V15H13Z'
        fill={fill}
      />
      <path
        d='M13 17V19H15V17H13Z'
        fill={fill}
      />
      <path
        d='M13 20V22H15V20H13Z'
        fill={fill}
      />
      <path
        d='M13 19V21H15V19H13Z'
        fill={fill}
      />
      <path
        d='M14 9V11H16V9H14Z'
        fill={fill}
      />
      <path
        d='M13 9V11H15V9H13Z'
        fill={fill}
      />
      <path
        d='M14 13V15H16V13H14Z'
        fill={fill}
      />
      <path
        d='M13 13V15H15V13H13Z'
        fill={fill}
      />
      <path
        d='M13 11V13H15V11H13Z'
        fill={fill}
      />
      <path
        d='M14 11V13H16V11H14Z'
        fill={fill}
      />
      <path
        d='M18 2V22H20V2H18Z'
        fill={fill}
      />
      <path
        d='M14 7V9H16V7H14Z'
        fill={fill}
      />
      <path
        d='M14 5V7H16V5H14Z'
        fill={fill}
      />
    </svg>
  );
}
