// Libraries
import type { WalletAccount } from '@mysten/wallet-standard';
import { action, makeAutoObservable, makeObservable, runInAction } from "mobx";
import { sumBy, head, map, find, last, isEmpty, compact } from 'lodash';

// Constants
import COINS from 'constants/coins';
import {
  ADDRESSES,
  SUI_SYSTEM_STATE_OBJECT_ID,
  SUI_SYSTEM_STATE_OBJECT_SHARED_VERSION
} from 'constants/addresses';

// Utils
import { TokenAmount } from 'utils/token-amount';
import { getAllValidators, getWhitelistedValidators } from 'utils/validator';

// Services
import * as StakingService from 'services/StakingService';
import { wrap } from 'utils/coin';
import { CoinStruct, PaginatedCoins, SuiClient, SuiSystemStateSummary } from '@mysten/sui/client';
import { Transaction } from "@mysten/sui/transactions";

type SelectedCoin = {
  objectId: string;
  digest: string;
  version: string;
  balance: number;
}

const DATA_REFRESH_INTERVAL = 30 * 1000;

class ClientStore {
  private _suiClient: SuiClient | null = null;
  private _account: WalletAccount | null = null;
  private _coins: PaginatedCoins | undefined | never[] = undefined;
  private _totalStakedBalance: TokenAmount | number = 0;
  private _totalSuiBalance: TokenAmount | number = 0;
  private _selectedCoins: SelectedCoin[] | undefined = undefined;
  private _stakingPackagingData: any = undefined;
  private _stateData: any = undefined;
  private _gasPrice: bigint = BigInt(0);
  private _suiSystemState: SuiSystemStateSummary | undefined = undefined;
  private _refreshInterval: ReturnType<typeof setInterval> | undefined = undefined;

  constructor() {
    this._initializeRefreshInterval();
    makeAutoObservable(this);

    this.refreshData = this.refreshData.bind(this);
  }

  /**
   * Initialize the refresh intervals
   */
  _initializeRefreshInterval() {
    this._refreshInterval = setInterval(() => this._refresh(), DATA_REFRESH_INTERVAL);
  }

  _refresh() {
    console.log('Refreshing all data...');

    this.hydrate();
  }

  public refreshData() {
    // Clear the existing intervals so that a new one can be setup for the
    // next DATA_REFRESH_INTERVAL time.
    clearInterval(this._refreshInterval);
    this._refreshInterval = setInterval(() => this._refresh(), DATA_REFRESH_INTERVAL);

    // Hydrate the data
    this._refresh();
  }

  /**
   * Expose the client
   */
  public get client() {
    return this._suiClient;
  }

  /**
   * Returns the sui coins data along with token amount 
   * and balance for each
   */
  public get suiCoins() {
    if (!this._coins || Array.isArray(!this._coins['data'])) {
      return [];
    }

    return map(this._coins['data'], (coin: CoinStruct) => {
      const _balance = new TokenAmount(coin?.balance, COINS.SUI.decimals);

      return Object.assign(
        {},
        coin,
        {
          _balance,
          _balanceAmount: _balance?.toNumber()
        }
      );
    });
  }

  public get totalValidators() {
    return this.validators?.length || 0;
  }

  /**
   * Returns a list of our whitelisted validators
   */
  public get validators() {
    if (isEmpty(this._stateData) || isEmpty(this._suiSystemState)) {
      return [];
    }

    const validators = getWhitelistedValidators(this._stateData);
    const allValidators = getAllValidators(this._suiSystemState);

    return compact(map(validators, (validatorAddress) => {
      return find(allValidators, { suiAddress: validatorAddress })
    }));
  }

  /**
   * Returns the total sui balance that the user holds
   */
  public get totalSuiBalance() {
    if (this._totalSuiBalance instanceof TokenAmount) {
      return this._totalSuiBalance.toNumber();
    }

    return 0;
  }

  /**
   * Returns the total rSui balance that the user holds
   */
  public get totalStakedBalance() {
    if (this._totalStakedBalance instanceof TokenAmount) {
      return this._totalStakedBalance.toNumber();
    }

    return 0;
  }

  public get packagingData() {
    return this._stakingPackagingData;
  }

  public get stateData() {
    return this._stateData;
  }

  public get gasPrice() {
    return this._gasPrice;
  }

  public get suiSystemState() {
    return this._suiSystemState;
  }

  /**
   * Fetches the packaging data based on the package ID
   */
  private async _updateStakingPackageData() {
    if (!this._suiClient) {
      return Promise.resolve({});
    }

    // Get the package details based on the staking package ID
    const stakingPackageData = await this._suiClient.getObject({
      id: ADDRESSES.PUBLISHED_AT,
      options: {
        showContent: true,
      }
    });

    this._stakingPackagingData = stakingPackageData;
  }

  /**
   * Fetches and updated the state data from the VersionedState
   * One example of usage of state data is that it contains the list of whitelisted validators.
   */
  private async _updateStateData() {
    if (!this._suiClient) {
      return Promise.resolve({});
    }

    console.log({ ADDRESSES });

    // Get the package details based on the staking package ID
    const lstData: any = await this._suiClient.getDynamicFields({
      parentId: ADDRESSES.RIVUS_LST,
      cursor: null,
      limit: null
    });

    const dynamicFieldData: any = await this._suiClient.getObject({
      id: lstData?.data?.[0]?.objectId,
      options: {
        showContent: true,
        showType: true
      }
    });

    // This is the versioned ID
    const parentId = dynamicFieldData?.data?.content?.fields?.value?.fields?.inner?.fields?.id?.id;

    // Get the versioned state's dynamic fields
    const versionStateData: any = await this._suiClient.getDynamicFields({
      parentId
    });

    // Finally, get the state data from the objectId
    const stateData: any = await this._suiClient.getObject({
      id: versionStateData?.data?.[0]?.objectId,
      options: {
        showContent: true,
        showType: true
      }
    });

    runInAction(() => {
      this._stateData = stateData;
    });
  }

  /**
   * Fetches and updates the latest SUI System State using standard API.
   */
  private async _updateLatestSuiSystemState() {
    if (!this._suiClient) {
      return Promise.resolve({});
    }

    const suiSystemState = await this._suiClient.getLatestSuiSystemState();

    runInAction(() => {
      this._suiSystemState = suiSystemState;
    });
  }

  /**
   * Fetches and updated the state data based on the STATE ID.
   * One example of usage of state data is that it contains the list of whitelisted validators.
   * 
   * @todo: Might have to write a refresh logic for refreshing the gas price frequently?
   */
  private async _updateGasPrice() {
    if (!this._suiClient) {
      return Promise.resolve({});
    }

    // Get the package details based on the staking package ID
    const gasPrice = await this._suiClient.getReferenceGasPrice();

    runInAction(() => {
      this._gasPrice = gasPrice;
    });
  }

  /**
   * Fetch all the coins based on the target deposit amount
   * @returns
   */
  private async _getCoins(depositAmount: number, type) {
    // Change the depositAmount to the decimal value
    // to avoid doing token amount conversion below when computing balance
    depositAmount = depositAmount * Math.pow(10, COINS.SUI.decimals);

    if (!this._account?.address) {
      return Promise.resolve([]);
    }

    let coins: SelectedCoin[] = [];

    let amount = 0;
    let hasNextPage = true;
    let nextCursor: string | null = null;

    for (; hasNextPage && amount < depositAmount;) {
      // Fetch the coin based on the user's account
      let coinsData = await this._suiClient?.getCoins({
        owner: this._account?.address,
        coinType: type,
        cursor: nextCursor
      });

      // Bail-out
      if (!coinsData) {
        break;
      }

      // Sort the coins based on balance descending
      coinsData.data.sort((a: CoinStruct, b: CoinStruct) => parseInt(a.balance) - parseInt(b.balance));

      for (const coin of coinsData.data) {
        const _balance = new TokenAmount(coin?.balance, COINS.SUI.decimals);

        coins.push({
          objectId: coin.coinObjectId,
          digest: coin.digest,
          version: coin.version,
          balance: _balance?.toNumber() || 0
        });

        amount = amount + parseInt(coin?.balance);

        console.log({ amount, b: parseInt(coin.balance) });

        // If the target amount is reached, we can bail-out since we don't
        // need to fetch more coins
        if (amount >= depositAmount)
          break;
      }

      nextCursor = coinsData.nextCursor;
      hasNextPage = coinsData.hasNextPage;
    }

    console.log({ coins });

    return coins;
  }

  /**
   * Selects the coins required for the deposit
   */
  public async selectCoins(depositAmount: number, type = COINS.SUI.type) {
    const selectedCoins = await this._getCoins(depositAmount, type);

    console.log({ selectedCoins });
    this._selectedCoins = selectedCoins;

    return selectedCoins;
  }

  /**
   * Update the balance for the user account
   */
  private async _updateBalance() {
    if (!this._account?.address) {
      return Promise.resolve(null);
    }

    const suiBalancePromise = this._suiClient?.getBalance({
      owner: this._account.address,
      coinType: COINS.SUI.type,
    });

    const rSuiBalancePromise = this._suiClient?.getBalance({
      owner: this._account.address,
      coinType: COINS.rSUI.type,
    });

    const [suiBalanceData, rSUIBalanceData] = await Promise.all([suiBalancePromise, rSuiBalancePromise]);

    runInAction(() => {
      this._totalStakedBalance = new TokenAmount(rSUIBalanceData?.totalBalance, COINS.rSUI.decimals);
      this._totalSuiBalance = new TokenAmount(suiBalanceData?.totalBalance, COINS.SUI.decimals);
    });
  }

  /**
   * Hydrates the store with the required data
   */
  public async hydrate() {
    // Wait for all the priority data to be loaded before
    // loading the rest of the data below
    await Promise.all([
      this._updateBalance()
    ]);

    this._updateStateData();
    this._updateGasPrice();
    this._updateLatestSuiSystemState();
    // this._updateStakingPackageData(); 
  }

  /**
   * Splits the gas free
   */
  async splitGasFees(txb: Transaction, gasCoin: any, objectId?: any) {
    const _objectId = objectId || gasCoin?.value?.Object?.ImmOrOwned?.objectId || gasCoin?.objectId;

    if (!_objectId) {
      console.log('Failed to split gas fees since objectID is missing');
      return txb;
    }

    // Setup the gas fees
    const [coin] = txb.splitCoins(txb.gas, [txb.pure.u64(this.gasPrice)]);

    // Transfer the split coin to a specific address for the gas.
    txb.transferObjects([coin], txb.pure(_objectId));

    return txb;
  }

  // splitMultiCoins(e, t) {
  //   const r = e.map(i=>wrap(this.txBlock, i))
  //     , n = r[0];
  //   return e.length > 1 && this.txBlock.mergeCoins(n, r.slice(1)),
  //   {
  //       splitedCoins: this.txBlock.splitCoins(n, tw(this.txBlock, t)),
  //       mergedCoin: n
  //   }
  // }

  // transferCoinToMany(e, t, r, n) {
  //   if (r.length !== n.length)
  //       throw new Error("transferSuiToMany: recipients.length !== amounts.length");

  //   const a = e.map(l=> wrap(this.txBlock, l));

  //   const {
  //       splitedCoins: i,
  //       mergedCoin: o
  //     } = this.splitMultiCoins(a, n);

  //   return r
  //     .map(l=> q3(this.txBlock, l))
  //     .forEach((transfer, index) => {
  //         this.txBlock.transferObjects([coins[index]], txb.pure(transfer.to))
  //     }),

  //   this.txBlock.transferObjects([o], q3(this.txBlock, t)),
  //   this
  // }

  /**
   * The selected coins received by this function should always be sorted by "balance".
   *
   */
  mergeCoins(txb: Transaction, coins: SelectedCoin[], depositAmount: number) {
    const wrappedCoins = coins.map(coin => wrap(txb, coin));
    const destinationCoin = head(coins);
    const wrappedDestinationCoin = head(wrappedCoins);

    const coinToSplit = last(coins);
    const wrappedCoinToSplit = last(wrappedCoins);

    // First compute the total amount required
    const totalAmount = sumBy(coins, 'balance');

    if (!coinToSplit || !destinationCoin) {
      return destinationCoin;
    }

    console.log({ depositAmount, totalAmount });

    // Split the last coin to only include the balance amount
    if (totalAmount > depositAmount) {
      let totalBalanceBeforeLastCoin = sumBy(coins.slice(0, -1), 'balance');
      const lastCoinBalance = coinToSplit.balance * Math.pow(10, COINS.SUI.decimals);

      // Convert the amount to SUI decimals
      const amountToDeposit = Math.floor(Number((depositAmount - totalBalanceBeforeLastCoin) * Math.pow(10, COINS.SUI.decimals)));
      const amountToSplit = Math.floor(Number(lastCoinBalance - amountToDeposit));

      // Create a split on the coin for the delta
      const [coin] = txb.splitCoins(wrappedCoinToSplit, [txb.pure.u64(amountToSplit)]);

      // Transfer the split coin into the destination coin
      txb.transferObjects([coin], txb.pure.address(destinationCoin.objectId));

      // Remove the last coin from the wrapped coins array
      wrappedCoins.pop();
    }

    if (wrappedCoins.length > 1) {
      // We are merging all the selected coins into the first coin
      // so that we can use that as a destination coin.
      // Ref: https://docs.sui.io/guides/developer/sui-101/building-ptb#available-transactions
      txb.mergeCoins(wrappedDestinationCoin, wrappedCoins.slice(1));
    }

    return wrappedDestinationCoin;
  }

  public async stake(depositAmount: number): Promise<Transaction | null> {
    if (!this.stateData?.data?.objectId) {
      return Promise.resolve(null);
    }

    const txb = new Transaction();

    // Move this to a helper since this is common
    const systemState = txb.sharedObjectRef(
      {
        objectId: SUI_SYSTEM_STATE_OBJECT_ID,
        initialSharedVersion: SUI_SYSTEM_STATE_OBJECT_SHARED_VERSION,
        mutable: true
      }
    );

    // @TODO: Instead of passing the coin type, create a utility function that handles this at each layer of the function usage
    // based on the coin identifier.
    const selectedCoins = await this.selectCoins(depositAmount, COINS.SUI.type);

    if (selectedCoins?.length < 1) {
      throw new Error('No coins found');
    }

    const depositCoin = this.mergeCoins(txb, selectedCoins, depositAmount);

    if (!depositAmount) {
      return Promise.reject(new Error('No deposit coin found.'));
    }

    const lst = txb.sharedObjectRef({
      objectId: ADDRESSES.RIVUS_LST,
      initialSharedVersion: ADDRESSES.RIVUS_LST_INITIAL_VERSION,
      mutable: true
    });

    // Add the gas fees - temporary hack - need to fix this by adding the gas fees to the total deposit amount first above
    // and then deducting the same here.
    this.splitGasFees(txb, depositCoin);

    // Initialize the move call with the arguments
    StakingService.mintRst(txb, {
      lst,
      systemState,
      asset: depositCoin
    });

    // @TODO: Migrate to use the transaction block inside the 
    // Client Store itself later.
    return txb;
  }

  public async unstake(unstakeAmount: number): Promise<Transaction | null> {
    if (!this.stateData?.data?.objectId) {
      return Promise.resolve(null);
    }

    const txb = new Transaction();

    // Move this to a helper since this is common
    const systemState = txb.sharedObjectRef({
      objectId: SUI_SYSTEM_STATE_OBJECT_ID,
      initialSharedVersion: SUI_SYSTEM_STATE_OBJECT_SHARED_VERSION,
      mutable: true
    });

    const lst = txb.sharedObjectRef({
      objectId: ADDRESSES.RIVUS_LST,
      initialSharedVersion: ADDRESSES.RIVUS_LST_INITIAL_VERSION,
      mutable: true
    });

    const selectedCoins = await this.selectCoins(unstakeAmount, COINS.rSUI.type);

    if (selectedCoins?.length < 1) {
      throw new Error('No coins found');
    }

    const withdrawCoin = this.mergeCoins(txb, selectedCoins, unstakeAmount);

    if (!withdrawCoin) {
      return Promise.reject(new Error('No unstake coin found.'));
    }


    // Initialize the move call with the arguments
    StakingService.burnRst(txb, {
      lst,
      systemState,
      asset: withdrawCoin
    });

    // Add the gas fees - temporary hack - need to fix this by adding the gas fees to the total deposit amount first above
    // and then deducting the same here.
    this.splitGasFees(txb, withdrawCoin);

    // const x = await this._suiClient?.devInspectTransactionBlock({
    //   sender: this._account?.address || '',
    //   transactionBlock: txb
    // });

    // console.log({ x });

    return txb;
  }

  /**
   * Initialize the suiClient and account
   *
   * @param suiClient
   * @param account
   */
  public init(suiClient: SuiClient, account: WalletAccount) {
    if (!this._suiClient) {
      this._suiClient = suiClient;
      this._account = account;

      this.hydrate();

      console.log({ suiClient, account });
    }
  }
}

const clientStore = new ClientStore();
export default clientStore;