import React from 'react';
import { IconProps } from 'base-components/Icon';

export function BackIcon(props: IconProps) {
  const { fill = 'var(--grey-100)', size = '24', ...rest } = props;
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...rest}
    >
      <rect
        y='11.5'
        width='2'
        height='2'
        fill={fill}
      />
      <rect
        x='2'
        y='13.5'
        width='2'
        height='2'
        fill={fill}
      />
      <rect
        x='4'
        y='15.5'
        width='2'
        height='2'
        fill={fill}
      />
      <rect
        x='6'
        y='17.5'
        width='2'
        height='2'
        fill={fill}
      />
      <rect
        x='8'
        y='19.5'
        width='2'
        height='2'
        fill={fill}
      />
      <rect
        x='10'
        y='19.5'
        width='2'
        height='2'
        fill={fill}
      />
      <rect
        x='12'
        y='19.5'
        width='2'
        height='2'
        fill={fill}
      />
      <rect
        x='10'
        y='17.5'
        width='2'
        height='2'
        fill={fill}
      />
      <rect
        x='12'
        y='17.5'
        width='2'
        height='2'
        fill={fill}
      />
      <rect
        x='10'
        y='15.5'
        width='2'
        height='2'
        fill={fill}
      />
      <rect
        x='12'
        y='15.5'
        width='2'
        height='2'
        fill={fill}
      />
      <rect
        x='10'
        y='14.5'
        width='2'
        height='2'
        fill={fill}
      />
      <rect
        x='12'
        y='14.5'
        width='2'
        height='2'
        fill={fill}
      />
      <rect
        x='14'
        y='14.5'
        width='2'
        height='2'
        fill={fill}
      />
      <rect
        x='14'
        y='8.5'
        width='2'
        height='2'
        fill={fill}
      />
      <rect
        x='16'
        y='8.5'
        width='2'
        height='2'
        fill={fill}
      />
      <rect
        x='18'
        y='8.5'
        width='2'
        height='2'
        fill={fill}
      />
      <rect
        x='20'
        y='8.5'
        width='2'
        height='2'
        fill={fill}
      />
      <rect
        x='16'
        y='14.5'
        width='2'
        height='2'
        fill={fill}
      />
      <rect
        x='18'
        y='14.5'
        width='2'
        height='2'
        fill={fill}
      />
      <rect
        x='20'
        y='14.5'
        width='2'
        height='2'
        fill={fill}
      />
      <rect
        x='22'
        y='14.5'
        width='2'
        height='2'
        fill={fill}
      />
      <rect
        x='22'
        y='11.5'
        width='2'
        height='2'
        fill={fill}
      />
      <rect
        x='20'
        y='11.5'
        width='2'
        height='2'
        fill={fill}
      />
      <rect
        x='22'
        y='10.5'
        width='2'
        height='2'
        fill={fill}
      />
      <rect
        x='20'
        y='10.5'
        width='2'
        height='2'
        fill={fill}
      />
      <rect
        x='22'
        y='12.5'
        width='2'
        height='2'
        fill={fill}
      />
      <rect
        x='20'
        y='12.5'
        width='2'
        height='2'
        fill={fill}
      />
      <rect
        x='22'
        y='8.5'
        width='2'
        height='2'
        fill={fill}
      />
      <rect
        x='10'
        y='3.5'
        width='2'
        height='2'
        fill={fill}
      />
      <rect
        x='12'
        y='3.5'
        width='2'
        height='2'
        fill={fill}
      />
      <rect
        x='10'
        y='5.5'
        width='2'
        height='2'
        fill={fill}
      />
      <rect
        x='12'
        y='5.5'
        width='2'
        height='2'
        fill={fill}
      />
      <rect
        x='10'
        y='7.5'
        width='2'
        height='2'
        fill={fill}
      />
      <rect
        x='12'
        y='7.5'
        width='2'
        height='2'
        fill={fill}
      />
      <rect
        x='12'
        y='5.5'
        width='2'
        height='2'
        fill={fill}
      />
      <rect
        x='12'
        y='7.5'
        width='2'
        height='2'
        fill={fill}
      />
      <rect
        x='10'
        y='8.5'
        width='2'
        height='2'
        fill={fill}
      />
      <rect
        x='12'
        y='8.5'
        width='2'
        height='2'
        fill={fill}
      />
      <rect
        x='2'
        y='9.5'
        width='2'
        height='2'
        fill={fill}
      />
      <rect
        x='4'
        y='7.5'
        width='2'
        height='2'
        fill={fill}
      />
      <rect
        x='6'
        y='5.5'
        width='2'
        height='2'
        fill={fill}
      />
      <rect
        x='8'
        y='3.5'
        width='2'
        height='2'
        fill={fill}
      />
    </svg>
  );
}
