import React, { useCallback } from "react";
import ConnectButton from "../ConnectButton";
import Logo from "../Logo";
import {
  BtnContainer,
  HeaderContainer,
  NavContainer,
  NavItem,
} from "./Header.styles";
import { useLocation, useNavigate } from "react-router-dom";
import { HEADER_ROUTES, ROUTES } from "../../constants/routes";
import { Button, Dropdown } from "base-components";
import { RPCNetworks } from "stores/RPCStore";
import { RPCStore } from "stores";
import { observer } from "mobx-react";

const RPC_OPTIONS = [
  {
    value: RPCNetworks.Suiscan,
    label: RPCNetworks.Suiscan,
  },
  {
    value: RPCNetworks.BlockVision,
    label: RPCNetworks.BlockVision,
  },
  {
    value: RPCNetworks.SuiOfficial,
    label: RPCNetworks.SuiOfficial,
  },
];

function Header() {
  const navigate = useNavigate();
  const location = useLocation();
  const { activeNetwork } = RPCStore;

  const onClickPath = useCallback(
    (path: string) => {
      navigate(path);
    },
    [navigate]
  );

  const onRpcSelect = useCallback(
    (network) => RPCStore.setActiveNetwork(network),
    []
  );

  if (location.pathname === ROUTES.HOME.path) {
    return null;
  }

  return (
    <nav>
      <HeaderContainer>
        <Logo onClick={() => onClickPath(ROUTES.HOME.path)} />

        <NavContainer>
          {HEADER_ROUTES.map(({ path, label }) => (
            <NavItem
              onClick={() => onClickPath(path)}
              active={path === location.pathname}
            >
              {label}
            </NavItem>
          ))}
        </NavContainer>

        <BtnContainer>
          {/* <Dropdown
            type={Button.TYPES.SECONDARY}
            size={Button.SIZES.S}
            buttonLabel={`Mainnet RPC (${activeNetwork})`}
            options={RPC_OPTIONS}
            activeOption={activeNetwork}
            onSelect={onRpcSelect}
          /> */}

          <ConnectButton />
        </BtnContainer>
      </HeaderContainer>
    </nav>
  );
}

export default observer(Header);
