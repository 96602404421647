import React from 'react';
import { IconProps } from 'base-components/Icon';

export function SettingsIcon(props: IconProps) {
  const { fill = 'var(--grey-100)', size = '24', ...rest } = props;
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 25 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...rest}
    >
      <path
        d='M5.30027 9.5999V6.40015H3.70024V7.99987H2.10022V9.5999H5.30027Z'
        fill={fill}
      />
      <path
        d='M14.9003 4.80088H18.1V3.20085H16.5003V1.60083H14.9003V4.80088Z'
        fill={fill}
      />
      <path
        d='M19.6995 14.3994V17.5992H21.2995V15.9994H22.8995V14.3994H19.6995Z'
        fill={fill}
      />
      <path
        d='M10.0997 0H14.8998V1.60002H10.0997V0Z'
        fill={fill}
      />
      <path
        d='M22.8998 9.60059H24.4998V14.4007H22.8998V9.60059Z'
        fill={fill}
      />
      <path
        d='M0.5 9.60059H2.10002V14.4007H0.5V9.60059Z'
        fill={fill}
      />
      <path
        d='M10.0997 6.40015H14.8998V8.00017H10.0997V6.40015Z'
        fill={fill}
      />
      <path
        d='M8.49976 8.00037H10.0998V9.60039H8.49976V8.00037Z'
        fill={fill}
      />
      <path
        d='M6.90051 9.60059H8.50054V14.4007H6.90051V9.60059Z'
        fill={fill}
      />
      <path
        d='M14.9003 8.00037H16.5003V9.60039H14.9003V8.00037Z'
        fill={fill}
      />
      <path
        d='M16.5002 9.60059H18.1003V14.4007H16.5002V9.60059Z'
        fill={fill}
      />
      <path
        d='M8.49976 14.3994H10.0998V15.9994H8.49976V14.3994Z'
        fill={fill}
      />
      <path
        d='M14.9003 14.3994H16.5003V15.9994H14.9003V14.3994Z'
        fill={fill}
      />
      <path
        d='M10.0997 16.0007H14.8998V17.6008H10.0997V16.0007Z'
        fill={fill}
      />
      <path
        d='M10.1003 19.1995H6.90051V20.7995H8.50024V22.3995H10.1003V19.1995Z'
        fill={fill}
      />
      <path
        d='M10.0997 22.3998H14.8998V23.9998H10.0997V22.3998Z'
        fill={fill}
      />
      <path
        d='M8.49963 4.80088H10.0997V1.60083H8.49963V3.20085H6.8999V4.80088H8.49963Z'
        fill={fill}
      />
      <path
        d='M6.90049 3.20024V1.60022H3.70044V3.20024H6.90049Z'
        fill={fill}
      />
      <path
        d='M3.70024 3.19983H2.10022V6.39988H3.70024V3.19983Z'
        fill={fill}
      />
      <path
        d='M19.6995 7.99987V9.5999H22.8995V7.99987H21.2995V6.40015H19.6995V7.99987Z'
        fill={fill}
      />
      <path
        d='M18.0995 1.60022H21.2995V3.20024H18.0995V1.60022Z'
        fill={fill}
      />
      <path
        d='M21.2996 3.19983H22.8996V6.39988H21.2996V3.19983Z'
        fill={fill}
      />
      <path
        d='M16.5003 19.1995H14.9003V22.3995H16.5003V20.7995H18.1V19.1995H16.5003Z'
        fill={fill}
      />
      <path
        d='M18.0995 20.8002H21.2995V22.4002H18.0995V20.8002Z'
        fill={fill}
      />
      <path
        d='M21.2996 17.5992H22.8996V20.7993H21.2996V17.5992Z'
        fill={fill}
      />
      <path
        d='M5.30027 16.0007V14.4006H2.10022V16.0007H3.70024V17.6004H5.30027V16.0007Z'
        fill={fill}
      />
      <path
        d='M2.09985 17.5992H3.69988V20.7993H2.09985V17.5992Z'
        fill={fill}
      />
      <path
        d='M3.70007 20.8002H6.90012V22.4002H3.70007V20.8002Z'
        fill={fill}
      />
    </svg>
  );
}
