import { SUI_SYSTEM_STATE_OBJECT_ID as _SUI_SYSTEM_STATE_OBJECT_ID } from '@mysten/sui/utils';

// Re-exported to keep all constants in one place
export const SUI_SYSTEM_STATE_OBJECT_ID = _SUI_SYSTEM_STATE_OBJECT_ID;

// Ref: https://github.com/MystenLabs/sui/blob/main/crates/sui-types/src/transaction.rs
// We didn't find a way to really fetch this data from the chain for now.
// Hence it's hardcoded for now.
export const SUI_SYSTEM_STATE_OBJECT_SHARED_VERSION = 1;

export const ALL_ADDRESSES = {
  testnet: {
    MODEL_ID: "0xd3958cd3491d3bee8c8fdd4134b327a8ade7608d9e57e985d220100f94aa7312",

    PUBLISHED_AT: "0xb25cb3db715e84213e30053dbcd5560315f4a034de1f6591971c4a83a43b1b2c",

    RIVUS_LST: "0x27353aab5d8e492b0529e462492646091f094ed8efec57e1cf0e33e32402ac27",

    RIVUS_LST_INITIAL_VERSION: 27809757
  },

  mainnet: {
    MODEL_ID: "0x8849cd0b94b6c24cc7818e2b50a711a0114f8b276266453a8790fd959d9f7f5f",

    PUBLISHED_AT: "0x8849cd0b94b6c24cc7818e2b50a711a0114f8b276266453a8790fd959d9f7f5f",

    RIVUS_LST: "0xef92d848901c56d63cd800c1184a5fe02be7ac56435bc07da8d6f9e5cf7c8555",

    RIVUS_LST_INITIAL_VERSION: 100227432,
  }
};

// Change this as needed - @TODO: Improve it further later.
// export const ADDRESSES = ALL_ADDRESSES.mainnet;
export const ADDRESSES = ALL_ADDRESSES.mainnet;
