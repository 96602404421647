import React from 'react';
import { IconProps } from 'base-components/Icon';

export function CheckIcon(props: IconProps) {
  const { fill = '#1A1A1A', size = '24', ...rest } = props;
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...rest}
    >
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M21 3H19V5H17V7H15V9H13V11H11V13H9H7V11H5V9H3H2H1H0V11V12V13V14H1V16H3V18H5V20H7V22H9H11V20H13V18H15V16H17V14H19V12H21V10H23V8H24V6V4H23V3H21ZM22 5V6H21V8H19V10H17V12H15V14H13V16H11V18H9H7V16H5V14H3V12H2V11H3H5V13H7V15H9H11V13H13V11H15V9H17V7H19V5H21H22Z'
        fill={fill}
      />
    </svg>
  );
}
