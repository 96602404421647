import styled from '@emotion/styled';
import { GRID_ALIGNMENTS, GRID_THEMES } from './Grid.types';
import { DISPLAY_SIZES } from 'constants/styles';

interface GridRowItemProps {
  alignment: GRID_ALIGNMENTS;
  flex?: number | string;
}

interface GridHeaderItemProps extends GridRowItemProps {
  active?: Boolean;
}

const ALIGNMENT_TO_STYLE = {
  [GRID_ALIGNMENTS.CENTER]: `
    justify-content: center;
    text-align: center;
  `,
  [GRID_ALIGNMENTS.LEFT]: `
  justify-content: flex-start;
  text-align: left;
  `,
  [GRID_ALIGNMENTS.RIGHT]: `
    justify-content: flex-end;
    text-align: right;
  `,
};

const THEME_TO_HEADER_STYLE = {
  [GRID_THEMES.PRIMARY]: 'background-color: var(--background-primary);',
  [GRID_THEMES.DARK]: 'background-color: var(--background-dark);',
};

const THEME_TO_ROW_STYLE = {
  [GRID_THEMES.PRIMARY]: 'background-color: var(--background-dark);',
  [GRID_THEMES.DARK]: 'background-color: var(--background-dark);',
};

const THEME_TO_BG_HOVER_STYLE = {
  [GRID_THEMES.PRIMARY]: 'background-color: var(--grey-20);',
  [GRID_THEMES.DARK]: 'background-color: var(--beige-20);',
};

export const GridContainer = styled.div`
  border-radius: 24px;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 24px 1px rgba(0, 0, 0, 0.2);
`;

export const GridHeader = styled.div<{ theme: GRID_THEMES }>`
  ${({ theme }) => THEME_TO_HEADER_STYLE[theme]}

  padding: 24px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;

  flex-grow: 0;

  @media (max-width: ${DISPLAY_SIZES.MOBILE_L}) {
    padding: 16px;
  }
`;

export const GridHeaderItem = styled.div<GridHeaderItemProps>`
  flex: ${({ flex }) => flex ?? 1};
  font-weight: ${({ active }) => (active ? 700 : 500)};
  ${({ alignment }) => ALIGNMENT_TO_STYLE[alignment]}

  font-size: 16px;
  line-height: 150%;
  color: var(--grey-80);
`;

export const GridEmptyContainer = styled.div`
  text-align: center;
  padding: 24px;
  background-color: var(--grey-00);
  border-radius: 8px;
  border: 2px solid var(--grey-100);
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface GridRowProps {
  theme: GRID_THEMES;
  isClickable: Boolean;
}

interface GridRowContainerProps {
  theme: GRID_THEMES;
}

export const GridRowContainer = styled.div<GridRowContainerProps>`
  overflow: auto;
  flex-grow: 1;
  border-radius: 8px;


  ${({ theme }) => THEME_TO_ROW_STYLE[theme]}
`;

export const GridRow = styled.div<GridRowProps>`

  padding: 24px;
  font-size: 16px;
  gap: 8px;
  /* border-radius: 8px; */
  border: 2px solid var(--grey-100);
  display: flex;
  align-items: center;
  border-bottom: thin solid var(--fill-secondary);

  @media (max-width: ${DISPLAY_SIZES.MOBILE_L}) {
    padding: 16px;
  }

  ${({ isClickable, theme }) =>
    isClickable
      ? `
    cursor: pointer;
    transition: 0.1s background-color;

    :hover {
      ${THEME_TO_BG_HOVER_STYLE[theme]}
    }
  `
      : ''}
`;

export const GridRowItem = styled.div<GridRowItemProps>`
  flex: ${({ flex }) => flex ?? 1};
  ${({ alignment }) => ALIGNMENT_TO_STYLE[alignment]}

  display: flex;
  align-items: center;
  overflow: hidden;
  white-space: pre;
  text-overflow: ellipsis;
  font-weight: 600;
`;

export const SpinnerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3rem 0;
  margin: auto;
`;
