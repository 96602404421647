import { HelpIcon } from './icons/HelpIcon';
import { StakeIcon } from './icons/StakeIcon';
import { UnstakeIcon } from './icons/UnstakeIcon';
import { CloseIcon } from './icons/CloseIcon';
import { ChevronIcon } from './icons/ChevronIcon';
import { UploadIcon } from './icons/UploadIcon';
import { VoltIcon } from './icons/VoltIcon';
import { BackIcon } from './icons/BackIcon';
import { SettingsIcon } from './icons/SettingsIcon';
import { RewindIcon } from './icons/RewindIcon';
import { PlayIcon } from './icons/PlayIcon';
import { PauseIcon } from './icons/PauseIcon';
import { ArrowLeftIcon } from './icons/ArrowLeftIcon';
import { ArrowRightIcon } from './icons/ArrowRightIcon';
import { InfoIcon } from './icons/InfoIcon';
import { CheckIcon } from './icons/CheckIcon';

enum ICON_NAMES {
  ARROW_LEFT = 'ARROW_LEFT',
  ARROW_RIGHT = 'ARROW_RIGHT',
  BACK = 'BACK',
  CHECK = 'CHECK',
  CHEVRON = 'CHEVRON',
  CLOSE = 'CLOSE',
  HELP = 'HELP',
  INFO = 'INFO',
  PAUSE = 'PAUSE',
  PLAY = 'PLAY',
  REWIND = 'REWIND',
  SETTINGS = 'SETTINGS',
  STAKE = 'STAKE',
  UNSTAKE = 'UNSTAKE',
  UPLOAD = 'UPLOAD',
  VOLT = 'VOLT',
}

export interface IconProps extends React.SVGAttributes<SVGElement> {
  size?: number | string;
}

const Icons = {
  [ICON_NAMES.ARROW_LEFT]: ArrowLeftIcon,
  [ICON_NAMES.ARROW_RIGHT]: ArrowRightIcon,
  [ICON_NAMES.BACK]: BackIcon,
  [ICON_NAMES.CHECK]: CheckIcon,
  [ICON_NAMES.CHEVRON]: ChevronIcon,
  [ICON_NAMES.CLOSE]: CloseIcon,
  [ICON_NAMES.HELP]: HelpIcon,
  [ICON_NAMES.INFO]: InfoIcon,
  [ICON_NAMES.PAUSE]: PauseIcon,
  [ICON_NAMES.PLAY]: PlayIcon,
  [ICON_NAMES.REWIND]: RewindIcon,
  [ICON_NAMES.SETTINGS]: SettingsIcon,
  [ICON_NAMES.STAKE]: StakeIcon,
  [ICON_NAMES.UNSTAKE]: UnstakeIcon,
  [ICON_NAMES.UPLOAD]: UploadIcon,
  [ICON_NAMES.VOLT]: VoltIcon,
};

export { ICON_NAMES, Icons };
