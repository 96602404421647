import { ADDRESSES } from "constants/addresses";
import { Transaction, TransactionObjectInput } from "@mysten/sui/transactions";

export interface MintRstArgs {
  lst: TransactionObjectInput;
  systemState: TransactionObjectInput;
  asset: TransactionObjectInput;
}

export function mintRst(txb: Transaction, args: MintRstArgs) {
  return txb.moveCall({
    target: `${ADDRESSES.PUBLISHED_AT}::lst::mint_rst`,
    arguments: [
      txb.object(args.lst),
      txb.object(args.systemState),
      txb.object(args.asset)
    ]
  });
}

export interface BurnRstArgs {
  lst: TransactionObjectInput;
  systemState: TransactionObjectInput;
  asset: TransactionObjectInput;
}

export function burnRst(txb: Transaction, args: BurnRstArgs) {
  return txb.moveCall({
    target: `${ADDRESSES.PUBLISHED_AT}::lst::burn_rst`,
    arguments: [
      txb.object(args.lst),
      txb.object(args.systemState),
      txb.object(args.asset)
    ]
  })
}