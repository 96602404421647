import React from 'react';

export function UploadIcon(props: React.SVGAttributes<SVGElement>) {
  const { fill = '#1A1A1A', ...rest } = props;
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...rest}
    >
      <rect
        x='13'
        y='3'
        width='2'
        height='2'
        transform='rotate(180 13 3)'
        fill={fill}
      />
      <rect
        x='11'
        y='5'
        width='2'
        height='2'
        transform='rotate(180 11 5)'
        fill={fill}
      />
      <rect
        x='9'
        y='7'
        width='2'
        height='2'
        transform='rotate(180 9 7)'
        fill={fill}
      />
      <rect
        x='7'
        y='9'
        width='2'
        height='2'
        transform='rotate(180 7 9)'
        fill={fill}
      />
      <rect
        x='7'
        y='11'
        width='2'
        height='2'
        transform='rotate(180 7 11)'
        fill={fill}
      />
      <rect
        x='9'
        y='11'
        width='2'
        height='2'
        transform='rotate(180 9 11)'
        fill={fill}
      />
      <rect
        x='9'
        y='11'
        width='2'
        height='2'
        transform='rotate(180 9 11)'
        fill={fill}
      />
      <rect
        x='7'
        y='12'
        width='2'
        height='2'
        transform='rotate(180 7 12)'
        fill={fill}
      />
      <rect
        x='9'
        y='12'
        width='2'
        height='2'
        transform='rotate(180 9 12)'
        fill={fill}
      />
      <rect
        x='17'
        y='12'
        width='2'
        height='2'
        transform='rotate(180 17 12)'
        fill={fill}
      />
      <rect
        x='19'
        y='12'
        width='2'
        height='2'
        transform='rotate(180 19 12)'
        fill={fill}
      />
      <rect
        x='17'
        y='11'
        width='2'
        height='2'
        transform='rotate(180 17 11)'
        fill={fill}
      />
      <rect
        x='15'
        y='11'
        width='2'
        height='2'
        transform='rotate(180 15 11)'
        fill={fill}
      />
      <rect
        x='15'
        y='13'
        width='2'
        height='2'
        transform='rotate(180 15 13)'
        fill={fill}
      />
      <rect
        x='13'
        y='19'
        width='2'
        height='2'
        transform='rotate(180 13 19)'
        fill={fill}
      />
      <rect
        x='15'
        y='19'
        width='2'
        height='2'
        transform='rotate(180 15 19)'
        fill={fill}
      />
      <rect
        x='11'
        y='17'
        width='2'
        height='2'
        transform='rotate(180 11 17)'
        fill={fill}
      />
      <rect
        x='11'
        y='15'
        width='2'
        height='2'
        transform='rotate(180 11 15)'
        fill={fill}
      />
      <rect
        x='11'
        y='19'
        width='2'
        height='2'
        transform='rotate(180 11 19)'
        fill={fill}
      />
      <rect
        x='15'
        y='15'
        width='2'
        height='2'
        transform='rotate(180 15 15)'
        fill={fill}
      />
      <rect
        x='15'
        y='17'
        width='2'
        height='2'
        transform='rotate(180 15 17)'
        fill={fill}
      />
      <rect
        x='11'
        y='11'
        width='2'
        height='2'
        transform='rotate(180 11 11)'
        fill={fill}
      />
      <rect
        x='11'
        y='13'
        width='2'
        height='2'
        transform='rotate(180 11 13)'
        fill={fill}
      />
      <rect
        x='15'
        y='5'
        width='2'
        height='2'
        transform='rotate(180 15 5)'
        fill={fill}
      />
      <rect
        x='17'
        y='7'
        width='2'
        height='2'
        transform='rotate(180 17 7)'
        fill={fill}
      />
      <rect
        x='19'
        y='9'
        width='2'
        height='2'
        transform='rotate(180 19 9)'
        fill={fill}
      />
      <rect
        x='19'
        y='11'
        width='2'
        height='2'
        transform='rotate(180 19 11)'
        fill={fill}
      />
      <rect
        width='2'
        height='2'
        transform='matrix(-1 0 0 1 3 22)'
        fill={fill}
      />
      <rect
        width='2'
        height='2'
        transform='matrix(-1 0 0 1 3 20)'
        fill={fill}
      />
      <rect
        width='2'
        height='2'
        transform='matrix(-1 0 0 1 5 22)'
        fill={fill}
      />
      <rect
        width='2'
        height='2'
        transform='matrix(-1 0 0 1 13 22)'
        fill={fill}
      />
      <rect
        width='2'
        height='2'
        transform='matrix(-1 0 0 1 11 22)'
        fill={fill}
      />
      <rect
        width='2'
        height='2'
        transform='matrix(-1 0 0 1 7 22)'
        fill={fill}
      />
      <rect
        width='2'
        height='2'
        transform='matrix(-1 0 0 1 9 22)'
        fill={fill}
      />
      <rect
        width='2'
        height='2'
        transform='matrix(-1 0 0 1 15 22)'
        fill={fill}
      />
      <rect
        width='2'
        height='2'
        transform='matrix(-1 0 0 1 17 22)'
        fill={fill}
      />
      <rect
        width='2'
        height='2'
        transform='matrix(-1 0 0 1 19 22)'
        fill={fill}
      />
      <rect
        width='2'
        height='2'
        transform='matrix(-1 0 0 1 23 22)'
        fill={fill}
      />
      <rect
        width='2'
        height='2'
        transform='matrix(-1 0 0 1 21 22)'
        fill={fill}
      />
      <rect
        width='2'
        height='2'
        transform='matrix(-1 0 0 1 23 20)'
        fill={fill}
      />
    </svg>
  );
}
