import styled from "@emotion/styled";

export const Container = styled.div`
  height: 100vh;
  width: 100vw;

  /* padding: 32px 64px;
  padding-top: 72px; */
  position: relative;

  font-size: 24px;
  text-align: center;
`;

export const ContentContainer = styled.div`

`;

export const Heading = styled.h1`
  font-size: 48px;
  line-height: 100%;
  margin-top: -24px;
`;

export const BackgroundContainer = styled.div`
  width: 250vw;
  height: 70vh;
  position: absolute;
  bottom: 0;
  overflow: hidden;
`;

export const LogoContainer = styled.div`
  border: 2px solid var(--fill-secondary);
  margin: auto;
  display: flex;
  flex-direction: column;
  /* gap: 36px; */
  align-items: center;
  justify-content: center;
  height: 96vh;
  margin: 0 2vh;
  border-radius: 12px;
  top: 2vh;
  left: 0;
  right: 0;
  position: absolute;
  z-index: 99;
  overflow: hidden;

  > div:nth-child(1) {
    transform: scale(2);
  }
`;

export const BuiltOnSuiText = styled.div`
  font-size: 20px;
  display: flex;
  gap: 6px;
  color: var(--sui-sea);
  align-items: center;
  font-weight: 400;
  margin-top: 36px;
  margin-bottom: 80px;
`;

export const SuiLogo = styled.img`
  height: 24px;
`;

export const LaunchAppBtnContainer = styled.div`
  position: absolute;
  top: 32px;
  right: 64px;
  z-index: 99;
`;

export const FooterContainer = styled.div`
  position: absolute;
  z-index: 99;

  bottom: 32px;
  margin: auto;

  width: 100vw;

  display: flex;
  justify-content: center;
  gap: 32px;
  align-items: center;
`;