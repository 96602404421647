import React from 'react';
import { IconProps } from 'base-components/Icon';

export function ArrowLeftIcon(props: IconProps) {
  const { fill = '#1A1A1A', size = '24', ...rest } = props;
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...rest}
    >
      <rect
        width='2'
        height='2'
        transform='matrix(1 0 0 -1 0 13)'
        fill={fill}
      />
      <rect
        width='2'
        height='2'
        transform='matrix(1 0 0 -1 2 11)'
        fill={fill}
      />
      <rect
        width='2'
        height='2'
        transform='matrix(1 0 0 -1 4 9)'
        fill={fill}
      />
      <rect
        width='2'
        height='2'
        transform='matrix(1 0 0 -1 6 7)'
        fill={fill}
      />
      <rect
        width='2'
        height='2'
        transform='matrix(1 0 0 -1 8 5)'
        fill={fill}
      />
      <rect
        width='2'
        height='2'
        transform='matrix(1 0 0 -1 10 5)'
        fill={fill}
      />
      <rect
        width='2'
        height='2'
        transform='matrix(1 0 0 -1 12 5)'
        fill={fill}
      />
      <rect
        width='2'
        height='2'
        transform='matrix(1 0 0 -1 10 7)'
        fill={fill}
      />
      <rect
        width='2'
        height='2'
        transform='matrix(1 0 0 -1 12 7)'
        fill={fill}
      />
      <rect
        width='2'
        height='2'
        transform='matrix(1 0 0 -1 10 9)'
        fill={fill}
      />
      <rect
        width='2'
        height='2'
        transform='matrix(1 0 0 -1 12 9)'
        fill={fill}
      />
      <rect
        width='2'
        height='2'
        transform='matrix(1 0 0 -1 10 10)'
        fill={fill}
      />
      <rect
        width='2'
        height='2'
        transform='matrix(1 0 0 -1 12 10)'
        fill={fill}
      />
      <rect
        width='2'
        height='2'
        transform='matrix(1 0 0 -1 14 10)'
        fill={fill}
      />
      <rect
        width='2'
        height='2'
        transform='matrix(1 0 0 -1 14 16)'
        fill={fill}
      />
      <rect
        width='2'
        height='2'
        transform='matrix(1 0 0 -1 16 16)'
        fill={fill}
      />
      <rect
        width='2'
        height='2'
        transform='matrix(1 0 0 -1 18 16)'
        fill={fill}
      />
      <rect
        width='2'
        height='2'
        transform='matrix(1 0 0 -1 20 16)'
        fill={fill}
      />
      <rect
        width='2'
        height='2'
        transform='matrix(1 0 0 -1 16 10)'
        fill={fill}
      />
      <rect
        width='2'
        height='2'
        transform='matrix(1 0 0 -1 18 10)'
        fill={fill}
      />
      <rect
        width='2'
        height='2'
        transform='matrix(1 0 0 -1 20 10)'
        fill={fill}
      />
      <rect
        width='2'
        height='2'
        transform='matrix(1 0 0 -1 22 10)'
        fill={fill}
      />
      <rect
        width='2'
        height='2'
        transform='matrix(1 0 0 -1 22 13)'
        fill={fill}
      />
      <rect
        width='2'
        height='2'
        transform='matrix(1 0 0 -1 20 13)'
        fill={fill}
      />
      <rect
        width='2'
        height='2'
        transform='matrix(1 0 0 -1 22 14)'
        fill={fill}
      />
      <rect
        width='2'
        height='2'
        transform='matrix(1 0 0 -1 20 14)'
        fill={fill}
      />
      <rect
        width='2'
        height='2'
        transform='matrix(1 0 0 -1 22 12)'
        fill={fill}
      />
      <rect
        width='2'
        height='2'
        transform='matrix(1 0 0 -1 20 12)'
        fill={fill}
      />
      <rect
        width='2'
        height='2'
        transform='matrix(1 0 0 -1 22 16)'
        fill={fill}
      />
      <rect
        width='2'
        height='2'
        transform='matrix(1 0 0 -1 10 21)'
        fill={fill}
      />
      <rect
        width='2'
        height='2'
        transform='matrix(1 0 0 -1 12 21)'
        fill={fill}
      />
      <rect
        width='2'
        height='2'
        transform='matrix(1 0 0 -1 10 19)'
        fill={fill}
      />
      <rect
        width='2'
        height='2'
        transform='matrix(1 0 0 -1 12 19)'
        fill={fill}
      />
      <rect
        width='2'
        height='2'
        transform='matrix(1 0 0 -1 10 17)'
        fill={fill}
      />
      <rect
        width='2'
        height='2'
        transform='matrix(1 0 0 -1 12 17)'
        fill={fill}
      />
      <rect
        width='2'
        height='2'
        transform='matrix(1 0 0 -1 12 19)'
        fill={fill}
      />
      <rect
        width='2'
        height='2'
        transform='matrix(1 0 0 -1 12 17)'
        fill={fill}
      />
      <rect
        width='2'
        height='2'
        transform='matrix(1 0 0 -1 10 16)'
        fill={fill}
      />
      <rect
        width='2'
        height='2'
        transform='matrix(1 0 0 -1 12 16)'
        fill={fill}
      />
      <rect
        width='2'
        height='2'
        transform='matrix(1 0 0 -1 2 15)'
        fill={fill}
      />
      <rect
        width='2'
        height='2'
        transform='matrix(1 0 0 -1 4 17)'
        fill={fill}
      />
      <rect
        width='2'
        height='2'
        transform='matrix(1 0 0 -1 6 19)'
        fill={fill}
      />
      <rect
        width='2'
        height='2'
        transform='matrix(1 0 0 -1 8 21)'
        fill={fill}
      />
    </svg>
  );
}
