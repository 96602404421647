import React from 'react';

export function StakeIcon(props: React.SVGAttributes<SVGElement>) {
  const { fill, ...rest } = props;
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...rest}
    >
      <g clip-path='url(#clip0_1170_4557)'>
        <rect
          x='1'
          y='11'
          width='2'
          height='2'
          fill='#1A1A1A'
        />
        <rect
          y='11'
          width='2'
          height='2'
          fill='#1A1A1A'
        />
        <rect
          x='3'
          y='11'
          width='2'
          height='2'
          fill='#1A1A1A'
        />
        <rect
          x='5'
          y='11'
          width='2'
          height='2'
          fill='#1A1A1A'
        />
        <rect
          x='5'
          y='9'
          width='2'
          height='2'
          fill='#1A1A1A'
        />
        <rect
          x='5'
          y='8'
          width='2'
          height='2'
          fill='#1A1A1A'
        />
        <rect
          x='5'
          y='13'
          width='2'
          height='2'
          fill='#1A1A1A'
        />
        <rect
          x='5'
          y='14'
          width='2'
          height='2'
          fill='#1A1A1A'
        />
        <rect
          x='7'
          y='8'
          width='2'
          height='2'
          fill='#1A1A1A'
        />
        <rect
          x='7'
          y='10'
          width='2'
          height='2'
          fill='#1A1A1A'
        />
        <rect
          x='7'
          y='12'
          width='2'
          height='2'
          fill='#1A1A1A'
        />
        <rect
          x='7'
          y='14'
          width='2'
          height='2'
          fill='#1A1A1A'
        />
        <rect
          x='7'
          y='6'
          width='2'
          height='2'
          fill='#1A1A1A'
        />
        <rect
          x='9'
          y='5'
          width='2'
          height='2'
          fill='#1A1A1A'
        />
        <rect
          x='9'
          y='17'
          width='2'
          height='2'
          fill='#1A1A1A'
        />
        <rect
          x='7'
          y='16'
          width='2'
          height='2'
          fill='#1A1A1A'
        />
        <rect
          x='11'
          y='5'
          width='2'
          height='2'
          fill='#1A1A1A'
        />
        <rect
          x='13'
          y='5'
          width='2'
          height='2'
          fill='#1A1A1A'
        />
        <rect
          x='19'
          y='7'
          width='2'
          height='2'
          fill='#1A1A1A'
        />
        <rect
          x='21'
          y='7'
          width='2'
          height='2'
          fill='#1A1A1A'
        />
        <rect
          x='22'
          y='7'
          width='2'
          height='2'
          fill='#1A1A1A'
        />
        <rect
          x='19'
          y='15'
          width='2'
          height='2'
          fill='#1A1A1A'
        />
        <rect
          x='21'
          y='15'
          width='2'
          height='2'
          fill='#1A1A1A'
        />
        <rect
          x='22'
          y='15'
          width='2'
          height='2'
          fill='#1A1A1A'
        />
        <rect
          x='15'
          y='5'
          width='2'
          height='2'
          fill='#1A1A1A'
        />
        <rect
          x='16'
          y='5'
          width='2'
          height='2'
          fill='#1A1A1A'
        />
        <rect
          x='11'
          y='17'
          width='2'
          height='2'
          fill='#1A1A1A'
        />
        <rect
          x='13'
          y='17'
          width='2'
          height='2'
          fill='#1A1A1A'
        />
        <rect
          x='15'
          y='17'
          width='2'
          height='2'
          fill='#1A1A1A'
        />
        <rect
          x='16'
          y='17'
          width='2'
          height='2'
          fill='#1A1A1A'
        />
        <rect
          x='18'
          y='3'
          width='2'
          height='2'
          fill='#1A1A1A'
        />
        <rect
          x='18'
          y='5'
          width='2'
          height='2'
          fill='#1A1A1A'
        />
        <rect
          x='18'
          y='7'
          width='2'
          height='2'
          fill='#1A1A1A'
        />
        <rect
          x='18'
          y='9'
          width='2'
          height='2'
          fill='#1A1A1A'
        />
        <rect
          x='18'
          y='11'
          width='2'
          height='2'
          fill='#1A1A1A'
        />
        <rect
          x='18'
          y='13'
          width='2'
          height='2'
          fill='#1A1A1A'
        />
        <rect
          x='18'
          y='15'
          width='2'
          height='2'
          fill='#1A1A1A'
        />
        <rect
          x='18'
          y='17'
          width='2'
          height='2'
          fill='#1A1A1A'
        />
        <rect
          x='18'
          y='19'
          width='2'
          height='2'
          fill='#1A1A1A'
        />
        <path
          d='M13.1875 14.1875C13.1875 14.3601 13.3274 14.5 13.5 14.5C13.6726 14.5 13.8125 14.3601 13.8125 14.1875V12.8125C13.8125 12.5364 14.0364 12.3125 14.3125 12.3125H15.6875C15.8601 12.3125 16 12.1726 16 12C16 11.8274 15.8601 11.6875 15.6875 11.6875H14.3125C14.0364 11.6875 13.8125 11.4636 13.8125 11.1875V9.8125C13.8125 9.63991 13.6726 9.5 13.5 9.5C13.3274 9.5 13.1875 9.63991 13.1875 9.8125V11.1875C13.1875 11.4636 12.9636 11.6875 12.6875 11.6875H11.3125C11.1399 11.6875 11 11.8274 11 12C11 12.1726 11.1399 12.3125 11.3125 12.3125H12.6875C12.9636 12.3125 13.1875 12.5364 13.1875 12.8125V14.1875Z'
          fill='#1A1A1A'
          stroke='#1A1A1A'
        />
      </g>
      <defs>
        <clipPath id='clip0_1170_4557'>
          <rect
            width='24'
            height='24'
            fill='white'
          />
        </clipPath>
      </defs>
    </svg>
  );
}
