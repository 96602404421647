import React, { useCallback } from "react";
import {
  BackgroundContainer,
  BuiltOnSuiText,
  Container,
  FooterContainer,
  Heading,
  LaunchAppBtnContainer,
  LogoContainer,
  SuiLogo,
} from "./Home.styles";

import Logo from "components/Logo";
import suiLogo from "assets/sui-logo.png";

import "./background.css";
import { Button } from "base-components";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "constants/routes";

import xLogo from "assets/x-logo.svg";
import discordLogo from "assets/discord-logo.svg";
import gitbookLogo from "assets/gitbook-logo.svg";
import {
  DiscordLogo,
  SocialItem,
  SocialLabel,
  SocialLogo,
} from "components/Footer/Footer.styles";

export default function Home() {
  const navigate = useNavigate();

  const onClickLaunch = useCallback(() => {
    navigate(ROUTES.STAKE.path);
  }, [navigate]);

  return (
    <Container>
      <LogoContainer>
        <Logo />

        <BuiltOnSuiText>
          Built on
          <SuiLogo src={suiLogo} />
        </BuiltOnSuiText>

        <Heading>Keep your stake fluid,</Heading>
        <Heading>watch rewards flow.</Heading>

        <Button.Secondary onClick={onClickLaunch}>Stake Now</Button.Secondary>
      </LogoContainer>

      <LaunchAppBtnContainer>
        <Button onClick={onClickLaunch}>Launch App</Button>
      </LaunchAppBtnContainer>

      <FooterContainer>
        <SocialItem>
          <SocialLogo src={xLogo} />
          <SocialLabel>Twitter</SocialLabel>
        </SocialItem>

        <SocialItem>
          <DiscordLogo src={discordLogo} />
          <SocialLabel>Discord</SocialLabel>
        </SocialItem>

        <SocialItem>
          <SocialLogo src={gitbookLogo} />
          <SocialLabel>Docs</SocialLabel>
        </SocialItem>
      </FooterContainer>

      <BackgroundContainer>
        <svg
          className="editorial"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          viewBox="0 24 150 28"
          preserveAspectRatio="none"
        >
          <defs>
            <path
              id="gentle-wave"
              d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
            />
          </defs>
          <g className="parallax">
            <use xlinkHref="#gentle-wave" x="50" y="0" fill="#4579e2" />
            <use xlinkHref="#gentle-wave" x="50" y="3" fill="#3461c1" />
            <use xlinkHref="#gentle-wave" x="50" y="6" fill="#2d55aa" />
          </g>
        </svg>
      </BackgroundContainer>
    </Container>
  );
}
