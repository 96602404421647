// Libraries
import { useCallback, useEffect, useState } from "react";
import {
  useCurrentAccount,
  useSignAndExecuteTransaction,
  useSuiClient,
} from "@mysten/dapp-kit";
import { observer } from "mobx-react";

// Components
import Tabs from "base-components/Tabs";

// Constants
import COINS from "constants/coins";

// Styles
import {
  Amount,
  AmountInput,
  ApyContainer,
  Asset,
  AssetLogo,
  AssetName,
  BottomDetails,
  Box,
  Button,
  Container,
  ContentContainer,
  ContentLeft,
  Details,
  DetailsLine,
  DetailsLineText,
  HoldersContainer,
  SeparatorCircleLeft,
  SeparatorCircleRight,
  SeparatorContainer,
  SeparatorInner,
  StakingRewardsImg,
  StatLabel,
  StatValueApy,
  StatValueHolders,
  StatValueTvl,
  StatsContainer,
  TvlContainer,
  BackgroundContainer
} from "./Staking.styles";

// Images
import suiLogo from "../../assets/core/sui.png";
import rSuiLogo from "../../assets/core/rSUI.png";

// Utils
import { TokenAmount } from "utils/token-amount";

// Stores
import { ClientStore } from "stores";

import stakingRewards from "../../assets/staking-rewards.png";
import { Transaction } from "@mysten/sui/transactions";

export const Separator = () => (
  <SeparatorContainer>
    <SeparatorInner />
    <SeparatorCircleLeft />
    <SeparatorCircleRight />
  </SeparatorContainer>
);

function Staking() {
  const account = useCurrentAccount();
  const suiClient = useSuiClient();

  useEffect(() => {
    if (!suiClient || !account) {
      return;
    }

    // Initialize the suiClient in the stores
    // @ts-ignore
    ClientStore.init(suiClient, account);
  }, [suiClient, account]);

  const [depositAmount, setDepositAmount] = useState(0);
  const onDepositInputChange = useCallback((e: any) => {
    setDepositAmount(parseFloat(e?.target?.value) || 0);
  }, []);

  const [withdrawAmount, setWithdrawAmount] = useState(0);
  const onWithdrawInputChange = useCallback((e: any) => {
    setWithdrawAmount(parseFloat(e?.target?.value) || 0);
  }, []);

  const { mutateAsync: signAndExecuteTransaction } =
    useSignAndExecuteTransaction({
      execute: async ({ bytes, signature }) =>
        await suiClient.executeTransactionBlock({
          transactionBlock: bytes,
          signature,
          options: {
            // Raw effects are required so the effects can be reported back to the wallet
            showRawEffects: true,
            // Select additional data to return
            showObjectChanges: true,
          },
        }),
    });

  let suiBalance: number = ClientStore.totalSuiBalance;
  let rSuiBalance: number = ClientStore.totalStakedBalance;

  const handleStakeSUI = async () => {
    console.log("Initializing stake txn");

    if (depositAmount <= 0) {
      console.log("Deposit amount needs to be higher than 0");
      return;
    }

    if (depositAmount > suiBalance) {
      console.log("Deposit amount is higher than the user account balance");
      // @TODO: Throw a toast/error state in the UI handling this
      return;
    }

    let txn = new Transaction();

    console.log({ txn });

    const txb = await ClientStore.stake(depositAmount);

    if (!txb) {
      return;
    }

    console.log("Executing the txn...");

    // Execute the txn
    try {
      const { digest, effects } = await signAndExecuteTransaction({
        transaction: txb,
      });

      console.log("Txn effects", { effects });
      console.log("Sui sent successfully", { digest, effects });
      ClientStore.refreshData();
    } catch (e) {
      console.log("Failed to execute txn~", e);
    }
  };

  const handleUnstakeSUI = async () => {
    console.log("Initializing unstake txn");

    if (withdrawAmount <= 0) {
      console.log("Withdraw amount needs to be higher than 0");
      return;
    }

    if (withdrawAmount > rSuiBalance) {
      console.log("Withdraw amount is higher than the user account balance");
      // @TODO: Throw a toast/error state in the UI handling this
      return;
    }

    let txn = new Transaction();

    console.log({ txn });

    const txb = await ClientStore.unstake(withdrawAmount);

    if (!txb) {
      return;
    }

    console.log("Sending txn...");

    console.log("Executing the txn...");

    // Execute the txn
    try {
      const { digest, effects } = await signAndExecuteTransaction({
        transaction: txb,
      });

      console.log("Txn effects", { effects });
      console.log("Sui sent successfully", { digest, effects });

      // TODO: Figure out to detect when a transaction has completed and then refresh
      ClientStore.refreshData();
    } catch (e) {
      console.log("Failed to execute txn~", e);
    }
  };

  return (
    <Container>
      <ContentContainer>
        <Tabs>
          <Tabs.Tab label="Stake">
            <Box>
              <Amount>
                <Asset>
                  <AssetLogo src={suiLogo} />
                  <AssetName>SUI</AssetName>
                </Asset>

                <AmountInput
                  type="number"
                  onChange={onDepositInputChange}
                  value={depositAmount}
                  placeholder="0"
                />
              </Amount>

              <Details>
                <DetailsLine>
                  <DetailsLineText>Balance</DetailsLineText>
                  <DetailsLineText>{suiBalance || 0} SUI</DetailsLineText>
                </DetailsLine>
                <DetailsLine>
                  <DetailsLineText>You will receive</DetailsLineText>
                  <DetailsLineText>0 rSUI</DetailsLineText>
                </DetailsLine>

                <DetailsLine>
                  <DetailsLineText>1 rSUI</DetailsLineText>
                  <DetailsLineText>≈1.01 SUI</DetailsLineText>
                </DetailsLine>

                <DetailsLine>
                  <DetailsLineText>Network fee</DetailsLineText>
                  <DetailsLineText>0 SUI</DetailsLineText>
                </DetailsLine>
              </Details>

              <Button onClick={handleStakeSUI}>Stake SUI</Button>

              <Separator />

              <BottomDetails>
                <DetailsLine>
                  <DetailsLineText>Epoch</DetailsLineText>
                  <DetailsLineText>Ends in: 01:10:23</DetailsLineText>
                </DetailsLine>

                <DetailsLine>
                  <DetailsLineText>Validators</DetailsLineText>
                  <DetailsLineText>
                    {ClientStore.totalValidators}
                  </DetailsLineText>
                </DetailsLine>
              </BottomDetails>
            </Box>
          </Tabs.Tab>
          <Tabs.Tab label="Unstake">
            <Box>
              <Amount>
                <Asset>
                  <AssetLogo src={rSuiLogo} />
                  <AssetName>rSUI</AssetName>
                </Asset>

                <AmountInput
                  type="number"
                  onChange={onWithdrawInputChange}
                  value={withdrawAmount}
                  placeholder="0"
                />
              </Amount>

              <Details>
                <DetailsLine>
                  <DetailsLineText>Balance</DetailsLineText>
                  <DetailsLineText>{rSuiBalance || 0} rSUI</DetailsLineText>
                </DetailsLine>
                <DetailsLine>
                  <DetailsLineText>You will receive</DetailsLineText>
                  <DetailsLineText>0 SUI</DetailsLineText>
                </DetailsLine>

                <DetailsLine>
                  <DetailsLineText>1 rSUI</DetailsLineText>
                  <DetailsLineText>≈1.01 SUI</DetailsLineText>
                </DetailsLine>

                <DetailsLine>
                  <DetailsLineText>Network fee</DetailsLineText>
                  <DetailsLineText>0 SUI</DetailsLineText>
                </DetailsLine>
              </Details>

              <Button onClick={handleUnstakeSUI}>Unstake SUI</Button>

              <Separator />

              <BottomDetails>
                <DetailsLine>
                  <DetailsLineText>Epoch</DetailsLineText>
                  <DetailsLineText>Ends in: 01:10:23</DetailsLineText>
                </DetailsLine>

                <DetailsLine>
                  <DetailsLineText>Validators</DetailsLineText>
                  <DetailsLineText>86</DetailsLineText>
                </DetailsLine>
              </BottomDetails>
            </Box>
          </Tabs.Tab>
        </Tabs>
      </ContentContainer>

      {/* <StatsContainer>
        <TvlContainer>
          <StatValueTvl>$123M</StatValueTvl>
          <StatLabel>TVL</StatLabel>
        </TvlContainer>

        <ApyContainer>
          <StatValueApy>8%</StatValueApy>
          <StatLabel>APY</StatLabel>
        </ApyContainer>

        <HoldersContainer>
          <StatValueHolders>1,234</StatValueHolders>
          <StatLabel>HOLDERS</StatLabel>
        </HoldersContainer>
      </StatsContainer> */}


      <BackgroundContainer>
        <svg
          className="editorial"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          viewBox="0 24 150 28"
          preserveAspectRatio="none"
        >
          <defs>
            <path
              id="gentle-wave"
              d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
            />
          </defs>
          <g className="parallax">
            <use xlinkHref="#gentle-wave" x="50" y="0" fill="#4579e2" />
            <use xlinkHref="#gentle-wave" x="50" y="3" fill="#3461c1" />
            <use xlinkHref="#gentle-wave" x="50" y="6" fill="#2d55aa" />
          </g>
        </svg>
      </BackgroundContainer>
    </Container>
  );
}

export default observer(Staking);
