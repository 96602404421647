import React from 'react';
import { IconProps } from 'base-components/Icon';

export function CloseIcon(props: IconProps) {
  const { fill = 'var(--grey-100)', size = '24', ...rest } = props;
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...rest}
    >
      <rect
        x='11'
        y='13'
        width='2'
        height='2'
        fill={fill}
      />
      <rect
        x='13'
        y='15'
        width='2'
        height='2'
        fill={fill}
      />
      <rect
        x='11'
        y='15'
        width='2'
        height='2'
        fill={fill}
      />
      <rect
        x='15'
        y='17'
        width='2'
        height='2'
        fill={fill}
      />
      <rect
        x='17'
        y='19'
        width='2'
        height='2'
        fill={fill}
      />
      <rect
        x='19'
        y='21'
        width='2'
        height='2'
        fill={fill}
      />
      <rect
        x='13'
        y='17'
        width='2'
        height='2'
        fill={fill}
      />
      <rect
        x='15'
        y='19'
        width='2'
        height='2'
        fill={fill}
      />
      <rect
        x='17'
        y='21'
        width='2'
        height='2'
        fill={fill}
      />
      <rect
        x='21'
        y='21'
        width='2'
        height='2'
        fill={fill}
      />
      <rect
        x='1'
        y='21'
        width='2'
        height='2'
        fill={fill}
      />
      <rect
        x='1'
        y='21'
        width='2'
        height='2'
        fill={fill}
      />
      <rect
        width='2'
        height='2'
        transform='matrix(-1 0 0 1 11 15)'
        fill={fill}
      />
      <rect
        width='2'
        height='2'
        transform='matrix(-1 0 0 1 9 17)'
        fill={fill}
      />
      <rect
        width='2'
        height='2'
        transform='matrix(-1 0 0 1 7 19)'
        fill={fill}
      />
      <rect
        width='2'
        height='2'
        transform='matrix(-1 0 0 1 5 21)'
        fill={fill}
      />
      <rect
        width='2'
        height='2'
        transform='matrix(-1 0 0 1 11 17)'
        fill={fill}
      />
      <rect
        width='2'
        height='2'
        transform='matrix(-1 0 0 1 9 19)'
        fill={fill}
      />
      <rect
        width='2'
        height='2'
        transform='matrix(-1 0 0 1 7 21)'
        fill={fill}
      />
      <rect
        x='11'
        y='13'
        width='2'
        height='2'
        transform='rotate(180 11 13)'
        fill={fill}
      />
      <rect
        x='9'
        y='15'
        width='2'
        height='2'
        transform='rotate(180 9 15)'
        fill={fill}
      />
      <rect
        x='7'
        y='17'
        width='2'
        height='2'
        transform='rotate(180 7 17)'
        fill={fill}
      />
      <rect
        x='5'
        y='19'
        width='2'
        height='2'
        transform='rotate(180 5 19)'
        fill={fill}
      />
      <rect
        x='3'
        y='21'
        width='2'
        height='2'
        transform='rotate(180 3 21)'
        fill={fill}
      />
      <rect
        x='9'
        y='11'
        width='2'
        height='2'
        transform='rotate(180 9 11)'
        fill={fill}
      />
      <rect
        x='7'
        y='9'
        width='2'
        height='2'
        transform='rotate(180 7 9)'
        fill={fill}
      />
      <rect
        x='5'
        y='7'
        width='2'
        height='2'
        transform='rotate(180 5 7)'
        fill={fill}
      />
      <rect
        x='21'
        y='19'
        width='2'
        height='2'
        transform='rotate(180 21 19)'
        fill={fill}
      />
      <rect
        x='23'
        y='21'
        width='2'
        height='2'
        transform='rotate(180 23 21)'
        fill={fill}
      />
      <rect
        x='19'
        y='17'
        width='2'
        height='2'
        transform='rotate(180 19 17)'
        fill={fill}
      />
      <rect
        x='17'
        y='15'
        width='2'
        height='2'
        transform='rotate(180 17 15)'
        fill={fill}
      />
      <rect
        x='15'
        y='13'
        width='2'
        height='2'
        transform='rotate(180 15 13)'
        fill={fill}
      />
      <rect
        x='11'
        y='9'
        width='2'
        height='2'
        transform='rotate(180 11 9)'
        fill={fill}
      />
      <rect
        x='13'
        y='11'
        width='2'
        height='2'
        transform='rotate(180 13 11)'
        fill={fill}
      />
      <rect
        x='9'
        y='7'
        width='2'
        height='2'
        transform='rotate(180 9 7)'
        fill={fill}
      />
      <rect
        x='7'
        y='5'
        width='2'
        height='2'
        transform='rotate(180 7 5)'
        fill={fill}
      />
      <rect
        x='5'
        y='3'
        width='2'
        height='2'
        transform='rotate(180 5 3)'
        fill={fill}
      />
      <rect
        width='2'
        height='2'
        transform='matrix(-1 0 0 1 21 1)'
        fill={fill}
      />
      <rect
        width='2'
        height='2'
        transform='matrix(-1 0 0 1 19 3)'
        fill={fill}
      />
      <rect
        width='2'
        height='2'
        transform='matrix(-1 0 0 1 17 5)'
        fill={fill}
      />
      <rect
        width='2'
        height='2'
        transform='matrix(-1 0 0 1 15 7)'
        fill={fill}
      />
      <rect
        x='3'
        y='5'
        width='2'
        height='2'
        transform='rotate(180 3 5)'
        fill={fill}
      />
      <rect
        width='2'
        height='2'
        transform='matrix(-1 0 0 1 23 3)'
        fill={fill}
      />
      <rect
        width='2'
        height='2'
        transform='matrix(-1 0 0 1 21 5)'
        fill={fill}
      />
      <rect
        width='2'
        height='2'
        transform='matrix(-1 0 0 1 19 7)'
        fill={fill}
      />
      <rect
        width='2'
        height='2'
        transform='matrix(-1 0 0 1 17 9)'
        fill={fill}
      />
    </svg>
  );
}
