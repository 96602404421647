import React, { useCallback, useState } from "react";

import noop from "lodash/noop";
import isEmpty from "lodash/isEmpty";

import { observer } from "mobx-react";

import { Spinner } from "base-components";
import { GridProps, GRID_ALIGNMENTS, GRID_THEMES } from "./Grid.types";
import {
  GridContainer,
  GridEmptyContainer,
  GridHeader,
  GridHeaderItem,
  GridRow,
  GridRowContainer,
  GridRowItem,
  SpinnerWrapper,
} from "./Grid.styles";

function Grid(props: GridProps) {
  const {
    columns = [],
    data = [],
    onRefresh = noop,
    emptyPlaceholder = "Looks like there is nothing to show here, have a great day!",
    theme = GRID_THEMES.PRIMARY,
    onRowClick,
    renderCustomRow,
    extraProps,
    isLoading = false,
  } = props;

  const [expandedRows, setExpandedRows] = useState(new Set());
  const [isRefreshing, setIsRefreshing] = useState(false);

  const handleOnRefresh = useCallback(async () => {
    setIsRefreshing(true);

    try {
      await onRefresh();
    } catch (err) {
      console.error(err);
    } finally {
      setIsRefreshing(false);
    }
  }, [onRefresh]);

  const handleExpand = useCallback(
    (index) => {
      if (expandedRows.has(index)) {
        expandedRows.delete(index);
      } else {
        expandedRows.add(index);
      }

      setExpandedRows(new Set(expandedRows));
    },
    [expandedRows]
  );

  const renderLoader = useCallback(() => {
    return (
      <SpinnerWrapper>
        <Spinner />
      </SpinnerWrapper>
    );
  }, []);

  const renderRows = useCallback(() => {
    if (isEmpty(data)) {
      return <GridEmptyContainer>{emptyPlaceholder}</GridEmptyContainer>;
    }

    return (
      <GridRowContainer theme={theme}>
        {data.map((row, rowIndex) => (
          <GridRow
            theme={theme}
            key={rowIndex}
            isClickable={Boolean(onRowClick)}
            onClick={() => {
              if (!onRowClick) {
                return;
              }

              onRowClick(row, rowIndex);
            }}
          >
            {columns.map((configItem, columnIndex) => (
              <GridRowItem
                alignment={configItem.alignment || GRID_ALIGNMENTS.LEFT}
                flex={configItem.flex}
                key={columnIndex}
              >
                {configItem.renderer(
                  row[configItem.accessor],
                  row,
                  rowIndex,
                  extraProps
                )}
              </GridRowItem>
            ))}
          </GridRow>
        ))}
      </GridRowContainer>
    );
  }, [columns, data, emptyPlaceholder, theme, onRowClick, extraProps]);

  return (
    <GridContainer>
      <GridHeader theme={theme}>
        {columns.map((configItem, index) => (
          <GridHeaderItem
            alignment={configItem.headerAlignment || GRID_ALIGNMENTS.CENTER}
            flex={configItem.flex}
            key={index}
          >
            {configItem.name}
          </GridHeaderItem>
        ))}
      </GridHeader>
      {renderCustomRow && renderCustomRow()}
      {isRefreshing || isLoading ? renderLoader() : renderRows()}
    </GridContainer>
  );
}

Grid.ALIGNMENTS = GRID_ALIGNMENTS;
Grid.THEMES = GRID_THEMES;

export default observer(Grid);
