import React, { useCallback, useState } from 'react';
import { ButtonProps, BUTTON_SIZES, BUTTON_TYPES } from './Button.types';
import {
  BUTTON_TYPE_MAP,
  ChildrenWrapper,
  IconWrapper,
  SpinnerWrapper,
} from './Button.styles';
import { Tooltip } from 'base-components';
import Spinner from 'base-components/Spinner';
import { Icons, ICON_NAMES } from 'base-components/Icon';

const noop = () => {};

const BUTTON_SIZE_TO_SPINNER_SIZE = {
  [BUTTON_SIZES.XS]: Spinner.SIZES.S,
  [BUTTON_SIZES.S]: Spinner.SIZES.S,
  [BUTTON_SIZES.M]: Spinner.SIZES.M,
  [BUTTON_SIZES.L]: Spinner.SIZES.L,
};

function Button(props: ButtonProps) {
  const {
    type = BUTTON_TYPES.PRIMARY,
    size = BUTTON_SIZES.S,
    onClick = noop,
    disabled,
    children,
    active,
    stretch,
    icon,
    tooltip,
    disabledTooltip,
    tooltipProps,
    ...restProps
  } = props;
  const ButtonComponent = BUTTON_TYPE_MAP[type];
  const Icon = icon ? Icons[icon] : null;
  const tooltipMsg = tooltip || (disabled && disabledTooltip);
  const Wrapper = tooltipMsg ? Tooltip : React.Fragment;
  const wrapperProps = tooltipMsg
    ? { message: tooltipMsg, ...tooltipProps }
    : {};

  const [loading, setLoading] = useState(false);

  const handleClick = useCallback(
    async (...args) => {
      if (disabled || loading) {
        return;
      }

      setLoading(true);

      let res;
      try {
        res = await onClick(...(args as []));
      } catch (e) {
      } finally {
        setLoading(false);
      }

      return res;
    },
    [onClick, disabled, loading]
  );

  return (
    <Wrapper {...wrapperProps}>
      <ButtonComponent
        disabled={disabled || loading}
        onClick={handleClick}
        size={size}
        active={active}
        stretch={stretch}
        {...restProps}
      >
        <>
          {loading ? (
            <SpinnerWrapper>
              <Spinner size={BUTTON_SIZE_TO_SPINNER_SIZE[size]} />
            </SpinnerWrapper>
          ) : null}

          <ChildrenWrapper loading={loading}>
            {Icon && (
              <IconWrapper
                type={type}
                size={size}
              >
                <Icon />
              </IconWrapper>
            )}
            {children}
          </ChildrenWrapper>
        </>
      </ButtonComponent>
    </Wrapper>
  );
}

Button.Primary = (props: ButtonProps) => (
  <Button
    type={BUTTON_TYPES.PRIMARY}
    {...props}
  />
);
Button.Secondary = (props: ButtonProps) => (
  <Button
    type={BUTTON_TYPES.SECONDARY}
    {...props}
  />
);
Button.Tertiary1 = (props: ButtonProps) => (
  <Button
    type={BUTTON_TYPES.TERTIARY1}
    {...props}
  />
);
Button.Tertiary2 = (props: ButtonProps) => (
  <Button
    type={BUTTON_TYPES.TERTIARY2}
    {...props}
  />
);
Button.Icon = (props: ButtonProps) => (
  <Button
    type={BUTTON_TYPES.ICON}
    size={BUTTON_SIZES.ICON}
    {...props}
  />
);
Button.Flashy = (props: ButtonProps) => (
  <Button
    type={BUTTON_TYPES.FLASHY}
    {...props}
  />
);

Button.TYPES = BUTTON_TYPES;
Button.SIZES = BUTTON_SIZES;
Button.ICONS = ICON_NAMES;

export default Button;
