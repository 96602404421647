import { toast } from 'react-toastify';
import {
  DEFAULT_TITLES,
  TOAST_TYPES,
  ToastBodyProps,
  ToastOptions,
} from './Toast.types';
import {
  CloseBtn,
  ToastContent,
  ToastContentContainer,
  ToastContentLink,
  ToastContentMsg,
  ToastContentTitle,
  ToastImage,
  ToastImageContainer,
} from './Toast.styles';
import { Icons } from 'base-components/Icon';

// import successImg from 'assets/toasts/success.png';
// import errorImg from 'assets/toasts/error.png';
// import submittedImg from 'assets/toasts/submitted.png';
import { getTransactionLink } from 'utils/external-link';

// const TOAST_IMG_MAP = {
//   [TOAST_TYPES.SUCCESS]: successImg,
//   [TOAST_TYPES.ERROR]: errorImg,
//   [TOAST_TYPES.SUBMITTED]: submittedImg,
// };

const showToast = (text, type, options?: ToastOptions) => {
  const { txId, title } = options || {};

  toast(
    <ToastBody
      message={text}
      title={title}
      type={type}
      txId={txId}
    />
  );
};

const ToastBody = ({
  message,
  title,
  type = TOAST_TYPES.SUCCESS,
  txId,
}: ToastBodyProps) => {
  // const toastImg = TOAST_IMG_MAP[type];
  const titleText = title || DEFAULT_TITLES[type];

  return (
    <ToastContentContainer>
      {/* <ToastImageContainer>
        <ToastImage src={toastImg} />
      </ToastImageContainer> */}

      <ToastContent>
        <ToastContentTitle>{titleText}</ToastContentTitle>
        <ToastContentMsg>{message}</ToastContentMsg>
        {txId ? (
          <ToastContentLink
            href={getTransactionLink(txId)}
            target='_blank'
            rel='noreferrer'
          >
            View transaction
          </ToastContentLink>
        ) : null}

        <CloseBtn>
          <Icons.CLOSE />
        </CloseBtn>
      </ToastContent>
    </ToastContentContainer>
  );
};

const Toast = {
  [TOAST_TYPES.SUCCESS]: (text: string, options?: ToastOptions) =>
    showToast(text, TOAST_TYPES.SUCCESS, options),
  [TOAST_TYPES.ERROR]: (text: string, options?: ToastOptions) =>
    showToast(text, TOAST_TYPES.ERROR, options),
  [TOAST_TYPES.SUBMITTED]: (text: string, options?: ToastOptions) =>
    showToast(text, TOAST_TYPES.SUBMITTED, options),
};

export default Toast;
