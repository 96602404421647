import styled from '@emotion/styled';
import MaterialTooltip from '@mui/material/Tooltip';
import { StyledTooltipProps } from './Tooltip.types';

export const StyledTooltip = styled(
  ({ isSimple, className, ...other }: StyledTooltipProps) => (
    <MaterialTooltip
      classes={{ tooltip: className }}
      arrow
      {...other}
    />
  )
)`
  font-family: IBM Plex Sans;
  color: var(--grey-100);
  padding: ${({ isSimple }) => (isSimple ? '16px 24px' : '0')};
  font-size: 16px;
  font-weight: 500;
  line-height: 150%;
  border-radius: 12px;
  border: 2px solid var(--grey-100);
  background-color: var(--beige-00);

  .MuiTooltip-arrow {
    color: var(--beige-00);

    ::before {
      color: var(--beige-00);
      background-color: var(--beige-00);
      border: 2px solid var(--grey-100);
      box-sizing: border-box;
    }
  }
`;

export const TooltipTitle = styled.div`
  padding: 16px 24px;
  background-color: var(--beige-00);
  border-bottom: 2px solid var(--grey-100);
  font-weight: 700;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
`;

export const TooltipBody = styled.div`
  padding: 16px 24px;
`;
