import React from 'react';

export function HelpIcon(props: React.SVGAttributes<SVGElement>) {
  const { fill, ...rest } = props;
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...rest}
    >
      <rect
        width='2'
        height='2'
        transform='matrix(1 0 0 -1 6 4)'
        fill='#1A1A1A'
      />
      <rect
        width='2'
        height='2'
        transform='matrix(1 0 0 -1 5 6)'
        fill='#1A1A1A'
      />
      <rect
        width='2'
        height='2'
        transform='matrix(1 0 0 -1 6 8)'
        fill='#1A1A1A'
      />
      <rect
        width='2'
        height='2'
        transform='matrix(1 0 0 -1 7 8)'
        fill='#1A1A1A'
      />
      <rect
        width='2'
        height='2'
        transform='matrix(1 0 0 -1 9 8)'
        fill='#1A1A1A'
      />
      <rect
        width='2'
        height='2'
        transform='matrix(1 0 0 -1 11 8)'
        fill='#1A1A1A'
      />
      <rect
        width='2'
        height='2'
        transform='matrix(1 0 0 -1 11 8)'
        fill='#1A1A1A'
      />
      <rect
        width='2'
        height='2'
        transform='matrix(1 0 0 -1 17 6)'
        fill='#1A1A1A'
      />
      <rect
        width='2'
        height='2'
        transform='matrix(1 0 0 -1 17 8)'
        fill='#1A1A1A'
      />
      <rect
        width='2'
        height='2'
        transform='matrix(1 0 0 -1 17 10)'
        fill='#1A1A1A'
      />
      <rect
        width='2'
        height='2'
        transform='matrix(1 0 0 -1 16 12)'
        fill='#1A1A1A'
      />
      <rect
        width='2'
        height='2'
        transform='matrix(1 0 0 -1 14 14)'
        fill='#1A1A1A'
      />
      <rect
        width='2'
        height='2'
        transform='matrix(1 0 0 -1 12 16)'
        fill='#1A1A1A'
      />
      <rect
        width='2'
        height='2'
        transform='matrix(1 0 0 -1 10 16)'
        fill='#1A1A1A'
      />
      <rect
        width='2'
        height='2'
        transform='matrix(1 0 0 -1 9 16)'
        fill='#1A1A1A'
      />
      <rect
        width='2'
        height='2'
        transform='matrix(1 0 0 -1 13 10)'
        fill='#1A1A1A'
      />
      <rect
        width='2'
        height='2'
        transform='matrix(1 0 0 -1 11 12)'
        fill='#1A1A1A'
      />
      <rect
        width='2'
        height='2'
        transform='matrix(1 0 0 -1 9 14)'
        fill='#1A1A1A'
      />
      <rect
        width='2'
        height='2'
        transform='matrix(1 0 0 -1 9 20)'
        fill='#1A1A1A'
      />
      <rect
        width='2'
        height='2'
        transform='matrix(1 0 0 -1 9 22)'
        fill='#1A1A1A'
      />
      <rect
        width='2'
        height='2'
        transform='matrix(1 0 0 -1 12 20)'
        fill='#1A1A1A'
      />
      <rect
        width='2'
        height='2'
        transform='matrix(1 0 0 -1 12 22)'
        fill='#1A1A1A'
      />
      <rect
        width='2'
        height='2'
        transform='matrix(1 0 0 -1 11 20)'
        fill='#1A1A1A'
      />
      <rect
        width='2'
        height='2'
        transform='matrix(1 0 0 -1 11 22)'
        fill='#1A1A1A'
      />
      <rect
        width='2'
        height='2'
        transform='matrix(1 0 0 -1 7 4)'
        fill='#1A1A1A'
      />
      <rect
        width='2'
        height='2'
        transform='matrix(1 0 0 -1 9 4)'
        fill='#1A1A1A'
      />
      <rect
        width='2'
        height='2'
        transform='matrix(1 0 0 -1 11 4)'
        fill='#1A1A1A'
      />
      <rect
        width='2'
        height='2'
        transform='matrix(1 0 0 -1 13 4)'
        fill='#1A1A1A'
      />
      <rect
        width='2'
        height='2'
        transform='matrix(1 0 0 -1 15 4)'
        fill='#1A1A1A'
      />
      <rect
        width='2'
        height='2'
        transform='matrix(1 0 0 -1 16 5)'
        fill='#1A1A1A'
      />
    </svg>
  );
}
