import React from 'react';
import { IconProps } from 'base-components/Icon';

export function PlayIcon(props: IconProps) {
  const { fill = 'var(--grey-100)', size = '24', ...rest } = props;
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...rest}
    >
      <path
        d='M17 9V11H19V9H17Z'
        fill={fill}
      />
      <path
        d='M19 11V13H21V11H19Z'
        fill={fill}
      />
      <path
        d='M17 13V15H19V13H17Z'
        fill={fill}
      />
      <path
        d='M15 15V17H17V15H15Z'
        fill={fill}
      />
      <path
        d='M13 17V19H15V17H13Z'
        fill={fill}
      />
      <path
        d='M9 21V23H11V21H9Z'
        fill={fill}
      />
      <path
        d='M11 19V21H13V19H11Z'
        fill={fill}
      />
      <path
        d='M7 17V19H9V17H7Z'
        fill={fill}
      />
      <path
        d='M7 21V23H9V21H7Z'
        fill={fill}
      />
      <path
        d='M7 19V21H9V19H7Z'
        fill={fill}
      />
      <path
        d='M7 15V17H9V15H7Z'
        fill={fill}
      />
      <path
        d='M9 1V3H11V1H9Z'
        fill={fill}
      />
      <path
        d='M7 3V5H9V3H7Z'
        fill={fill}
      />
      <path
        d='M7 1V3H9V1H7Z'
        fill={fill}
      />
      <path
        d='M6 3V5H8V3H6Z'
        fill={fill}
      />
      <path
        d='M6 1V3H8V1H6Z'
        fill={fill}
      />
      <path
        d='M6 5V7H8V5H6Z'
        fill={fill}
      />
      <path
        d='M6 7V9H8V7H6Z'
        fill={fill}
      />
      <path
        d='M6 15V17H8V15H6Z'
        fill={fill}
      />
      <path
        d='M6 17V19H8V17H6Z'
        fill={fill}
      />
      <path
        d='M6 21V23H8V21H6Z'
        fill={fill}
      />
      <path
        d='M6 19V21H8V19H6Z'
        fill={fill}
      />
      <path
        d='M7 9V11H9V9H7Z'
        fill={fill}
      />
      <path
        d='M6 9V11H8V9H6Z'
        fill={fill}
      />
      <path
        d='M7 13V15H9V13H7Z'
        fill={fill}
      />
      <path
        d='M6 13V15H8V13H6Z'
        fill={fill}
      />
      <path
        d='M6 11V13H8V11H6Z'
        fill={fill}
      />
      <path
        d='M7 11V13H9V11H7Z'
        fill={fill}
      />
      <path
        d='M15 7V9H17V7H15Z'
        fill={fill}
      />
      <path
        d='M13 5V7H15V5H13Z'
        fill={fill}
      />
      <path
        d='M11 3V5H13V3H11Z'
        fill={fill}
      />
      <path
        d='M7 7V9H9V7H7Z'
        fill={fill}
      />
      <path
        d='M7 5V7H9V5H7Z'
        fill={fill}
      />
    </svg>
  );
}
