import { isNil } from 'lodash';
import BigNumber from 'bignumber.js';

export const getFormattedNumber = function (
  num,
  minimumFractionDigits = 2,
  maximumFractionDigits = 2
) {
  // `isNil` check for `null` or `undefined`
  // `num !== num` check for `NaN`
  if (isNil(num) || isNaN(num)) {
    return Number(0).toFixed(2);
  }

  if (num instanceof BigNumber) {
    num = num.toNumber();
  }

  // Less than 100k
  if (num < 100000) {
    return num.toLocaleString(undefined, {
      minimumFractionDigits,
      maximumFractionDigits,
    });
  }

  // Less than 1 million
  if (num < 1000000) {
    return `${(num / 1000).toLocaleString(undefined, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 1,
    })}K`;
  }

  // Million, Billion, Trillion, quadrillion, Quintillion, sextillion, Septillion
  const units = ['M', 'B', 'T', 'q', 'Q', 's', 'S'],
    unit = Math.floor((num / 1.0e1).toFixed(0).toString().length),
    r = unit % 3,
    x = Math.abs(Number(num)) / Number(Number('1.0e+' + (unit - r)).toFixed(2));

  return x.toFixed(2) + '' + units[Math.floor(unit / 3) - 2];
};

export const getPrintableTimeLeft = (timeLeft: number) => {
  const days = Math.floor(timeLeft / (60 * 60 * 24));
  const hours = Math.floor((timeLeft / (60 * 60)) % 24);
  const minutes = Math.floor((timeLeft / 60) % 60);

  if (days < 1 && hours < 1) {
    return `${minutes}m`;
  }

  if (days < 1) {
    return `${hours}h ${minutes}m`;
  }

  return `${days}d ${hours}h ${minutes}m`;
};
