const COINS = {
  SUI: {
    type: '0x2::sui::SUI',
    decimals: 9
  },
  rSUI: {
    type: '0x8849cd0b94b6c24cc7818e2b50a711a0114f8b276266453a8790fd959d9f7f5f::rst::RST',
    decimals: 9
  }
}

export default COINS;
