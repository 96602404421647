import styled from "@emotion/styled";

export const Container = styled.div`
  height: calc(100vh - 162px);
  width: 80vw;
  margin: auto;

  padding: 32px 64px;
  position: relative;

  font-size: 24px;
  
  /* overflow: auto; */
`;

export const Heading = styled.h1`
  font-size: 36px;
  line-height: 48px;
  text-align: center;
`;

export const GridContainer = styled.div`
  height: calc(100% - 80px);
`;

export const ValidatorColumn = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const ValidatorImg = styled.img`
  height: 24px;
  width: 24px;
  border-radius: 50%;
`;

export const ValidatorName = styled.span`

`;