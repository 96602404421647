import styled from '@emotion/styled';
import { ButtonProps, BUTTON_SIZES, BUTTON_TYPES } from './Button.types';

const SIZE_TO_PADDING = {
  [BUTTON_SIZES.XS]: '8px 16px',
  [BUTTON_SIZES.S]: '12px 16px',
  [BUTTON_SIZES.M]: '12px 24px',
  [BUTTON_SIZES.L]: '16px 32px',
  [BUTTON_SIZES.ICON]: '12px 10px',
};

const SIZE_TO_FONT_SIZE = {
  [BUTTON_SIZES.XS]: 16,
  [BUTTON_SIZES.S]: 16,
  [BUTTON_SIZES.M]: 18,
  [BUTTON_SIZES.L]: 20,
  [BUTTON_SIZES.ICON]: 16,
};

const SIZE_TO_FONT_WEIGHT = {
  [BUTTON_SIZES.XS]: '600',
  [BUTTON_SIZES.S]: '600',
  [BUTTON_SIZES.M]: '700',
  [BUTTON_SIZES.L]: '700',
  [BUTTON_SIZES.ICON]: '600',
};

const SIZE_TO_HEIGHT = {
  [BUTTON_SIZES.XS]: '40px',
  [BUTTON_SIZES.S]: '48px',
  [BUTTON_SIZES.M]: '56px',
  [BUTTON_SIZES.L]: '62px',
  [BUTTON_SIZES.ICON]: '48px',
};

const BaseButton = styled.button<ButtonProps>`
  padding: ${({ size }) => SIZE_TO_PADDING[size!]};
  font-size: ${({ size }) => SIZE_TO_FONT_SIZE[size!]}px;
  font-weight: ${({ size }) => SIZE_TO_FONT_WEIGHT[size!]};
  height: ${({ size }) => SIZE_TO_HEIGHT[size!]};
  width: ${({ stretch }) => stretch && '100%'};

  position: relative;
  box-sizing: border-box;
  line-height: 150%;
  /* border-radius: 8px; */
  /* font-family: IBM Plex Sans, sans-serif; */
  /* cursor: pointer; */
  /* transition: 0.1s margin, 0.1s box-shadow, 0.3s background-color; */
  /* margin-bottom: 3px; */
  /* margin-right: 2px; */

  display: flex;
  align-items: center;
  justify-content: center;
  /* color: var(--grey-100); */


  border-radius: 36px;
  padding: 10px 24px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;

  transition: 0.1s background-color;

  /* :active {
    margin-top: 3px;
    margin-left: 2px;
    margin-bottom: 0;
    margin-right: 0;
    transition: 0.1s margin, 0.1s box-shadow;
  } */

  ${({ disabled }) =>
    disabled
      ? `
    background-color: var(--grey-40) !important;
    cursor: not-allowed;
    box-shadow: none !important;

    :active {
      margin-bottom: 3px !important;
      margin-right: 2px !important;
      margin-top: 0px !important;
      margin-left: 0px !important;
    }
  `
      : ''}
`;

const PrimaryButton = styled(BaseButton)`
  background-color: var(--fill-primary);
  color: var(--text-primary);
  border: none;
  /* box-shadow: 4px 6px 0px var(--grey-100); */


  :active {
    /* box-shadow: 2px 3px 0px var(--grey-100); */
  }

  :hover {
    background-color: var(--fill-secondary);
  }
`;

const SecondaryButton = styled(BaseButton)`
  background-color: transparent;
  border: 1px solid var(--fill-primary);
  color: var(--text-primary);

  /* :active {
    box-shadow: 2px 3px 0px var(--pink-60);
  } */

  :hover {
    background-color: var(--fill-primary);
  }
`;

const TertiaryButton1 = styled(BaseButton)`
  background-color: var(--yellow-40);
  border: 2px solid var(--grey-100);
  margin-bottom: 2px;
  margin-right: 0;

  :active {
    margin-top: 2px;
    margin-left: 0;
    margin-bottom: 0;
    margin-right: 0;
  }

  :hover {
    background-color: var(--yellow-60);
  }

  ${({ disabled }) =>
    disabled
      ? `
    :active {
      margin-bottom: 2px !important;
      margin-right: 0px !important;
      margin-top: 0px !important;
      margin-left: 0px !important;
    }
  `
      : ''}
`;

const TertiaryButton2 = styled(BaseButton)`
  background-color: var(--grey-00);
  border: 2px solid var(--grey-100);
  margin-bottom: 2px;
  margin-right: 0;

  :active {
    margin-top: 2px;
    margin-left: 0;
    margin-bottom: 0;
    margin-right: 0;
  }

  :hover {
    background-color: var(--grey-20-again);
  }

  ${({ disabled }) =>
    disabled
      ? `
    :active {
      margin-bottom: 2px !important;
      margin-right: 0px !important;
      margin-top: 0px !important;
      margin-left: 0px !important;
    }
  `
      : ''}
`;

const IconButton = styled(BaseButton)`
  background-color: ${(props) =>
    props.active ? 'var(--secondary-green-00)' : 'var(--grey-00)'};
  border: 2px solid var(--grey-100);
  box-shadow: 4px 4px 0px var(--grey-100);
  line-height: unset;
  font-size: unset;
  font-weight: unset;

  margin-top: 0px;
  margin-left: 0px;
  margin-bottom: 2px;
  margin-right: 2px;

  :active {
    box-shadow: 2px 2px 0px var(--grey-100);
    margin-top: 2px;
    margin-left: 2px;
    margin-bottom: 0;
    margin-right: 0;
  }

  :hover {
    background-color: var(--secondary-green-00);
  }

  ${({ disabled }) =>
    disabled
      ? `
    :active {
      margin-bottom: 2px !important;
      margin-right: 2px !important;
      margin-top: 0px !important;
      margin-left: 0px !important;
    }
  `
      : ''}
`;

export const FlashyButton = styled(TertiaryButton1)`
  background: linear-gradient(81.84deg, #bca4ff 6%, #73e2cb 40%, #bca4ff 94%);
  color: var(--grey-100);

  background-size: 500% 100%;
  animation: gradient 1s ease-in-out infinite;

  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 50% 50%;
    }
    100% {
      background-position: 100% 50%;
    }
  }

  :hover {
    animation: gradient 0.7s ease-in-out infinite;
  }

  ${({ disabled }) => (disabled ? 'background: var(--grey-20-again);' : '')}
`;

export const ChildrenWrapper = styled.div<{ loading: boolean }>`
  visibility: ${({ loading }) => (loading ? 'hidden' : 'visible')};
  display: flex;
  align-items: center;
`;

export const SpinnerWrapper = styled.div`
  position: absolute;
  margin: auto;
  display: flex;
`;

export const IconWrapper = styled.div<{
  size: BUTTON_SIZES;
  type: BUTTON_TYPES;
}>`
  margin-right: ${({ size, type }) =>
    type !== BUTTON_TYPES.ICON && SIZE_TO_FONT_SIZE[size!] / 2}px;
  display: flex;

  svg {
    height: ${({ size }) => SIZE_TO_FONT_SIZE[size!] * 1.5}px;
    width: ${({ size }) => SIZE_TO_FONT_SIZE[size!] * 1.5}px;
  }
`;

export const BUTTON_TYPE_MAP = {
  [BUTTON_TYPES.PRIMARY]: PrimaryButton,
  [BUTTON_TYPES.SECONDARY]: SecondaryButton,
  [BUTTON_TYPES.TERTIARY1]: TertiaryButton1,
  [BUTTON_TYPES.TERTIARY2]: TertiaryButton2,
  [BUTTON_TYPES.ICON]: IconButton,
  [BUTTON_TYPES.FLASHY]: FlashyButton,
};
