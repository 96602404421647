import React from "react";
import {
  Container,
  DiscordLogo,
  SocialItem,
  SocialLabel,
  SocialLogo,
} from "./Footer.styles";

import xLogo from "../../assets/x-logo.svg";
import discordLogo from "../../assets/discord-logo.svg";
import gitbookLogo from "../../assets/gitbook-logo.svg";
import { useLocation } from "react-router-dom";
import { ROUTES } from "constants/routes";

export default function Footer() {
  const location = useLocation();

  if (location.pathname === ROUTES.HOME.path) {
    return null;
  }

  return (
    <Container>
      <SocialItem>
        <SocialLogo src={xLogo} />
        <SocialLabel>Twitter</SocialLabel>
      </SocialItem>

      <SocialItem>
        <DiscordLogo src={discordLogo} />
        <SocialLabel>Discord</SocialLabel>
      </SocialItem>

      <SocialItem>
        <SocialLogo src={gitbookLogo} />
        <SocialLabel>Docs</SocialLabel>
      </SocialItem>
    </Container>
  );
}
