import { ReactNode } from 'react';
import { ICON_NAMES } from 'base-components/Icon';
import {
  TooltipMessage,
  TooltipProps,
} from 'base-components/Tooltip/Tooltip.types';

export enum BUTTON_TYPES {
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY',
  TERTIARY1 = 'TERTIARY1',
  TERTIARY2 = 'TERTIARY2',
  ICON = 'ICON',
  FLASHY = 'FLASHY',
}

export enum BUTTON_SIZES {
  XS = 'XS',
  S = 'S',
  M = 'M',
  L = 'L',
  ICON = 'ICON',
}

export interface ButtonProps {
  type?: BUTTON_TYPES;
  size?: BUTTON_SIZES;
  children?: string | ReactNode;
  onClick?: () => void;
  disabled?: boolean;
  active?: boolean;
  stretch?: boolean;
  icon?: ICON_NAMES | undefined | null;
  tooltip?: TooltipMessage;
  disabledTooltip?: TooltipMessage;
  tooltipProps?: Omit<TooltipProps, 'children'>;
}
