import React from 'react';
import { isString } from 'lodash';
import { TooltipProps } from './Tooltip.types';
import { StyledTooltip, TooltipBody, TooltipTitle } from './Tooltip.styles';

const Tooltip = ({ message, placement = 'bottom', ...rest }: TooltipProps) => {
  if (!message) {
    return null;
  }

  if (isString(message)) {
    return (
      <StyledTooltip
        isSimple
        title={message}
        placement={placement}
        {...rest}
      />
    );
  }

  const { title, body } = message;

  return (
    <StyledTooltip
      title={
        <>
          <TooltipTitle>{title}</TooltipTitle>
          <TooltipBody>{body}</TooltipBody>
        </>
      }
      placement={placement}
      {...rest}
    />
  );
};

export default Tooltip;
