import { MenuButton, Menu } from "@szhsin/react-menu";
import styled from "@emotion/styled";
import { keyframes } from "@emotion/react";

import {
  menuSelector,
  menuItemSelector,
  menuDividerSelector,
} from "@szhsin/react-menu/style-utils";
import { MenuItem } from "@szhsin/react-menu";

const menuShow = keyframes`
  from {
    opacity: 0;
  }
`;
const menuHide = keyframes`
  to {
    opacity: 0;
  }
`;

export const DropdownMenu = styled(Menu)`
  ${menuSelector.name} {
    z-index: 1000;
    box-sizing: border-box;
    list-style: none;
    user-select: none;

    padding: 12px;
    font-size: 16px;
    font-weight: 500;
    line-height: 150%;

    /* border: 1px solid var(--fill-primary); */
    margin-top: 8px;
    box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.4);
    border-radius: 16px;
    background-color: var(--background-primary);
    min-width: 240px;
  }

  ${menuSelector.name}:focus,${menuItemSelector.name}:focus {
    outline: none;
  }

  ${menuSelector.stateOpening} {
    animation: ${menuShow} 0.15s ease-out;
  }

  ${menuSelector.stateClosing} {
    animation: ${menuHide} 0.2s ease-out forwards;
  }

  ${menuItemSelector.name} {
    cursor: pointer;
    border-radius: 8px;
    padding: 12px;
    /* margin: 4px 0; */
  }

  ${menuItemSelector.hover} {
    background-color: var(--fill-primary);
  }

  ${menuItemSelector.disabled} {
    cursor: default;
    color: #aaa;
  }

  ${menuItemSelector.submenu} {
    position: relative;
    &::after {
      position: absolute;
      width: 7px;
      right: 0.625rem;
    }
  }

  ${menuDividerSelector.name} {
    height: 1px;
    margin: 0.5rem 0.625rem;
    background-color: rgba(0, 0, 0, 0.12);
  }
`;

export const DropdownMenuButton = styled(MenuButton)`
  border: none;
  background: transparent;
`;

interface DropdownMenuItemProps {
  isActive?: boolean;
}

export const DropdownMenuItem = styled(MenuItem)<DropdownMenuItemProps>`
  ${({ isActive }) =>
    isActive ? `background-color: var(--fill-secondary);` : ""}
`;
