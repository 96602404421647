import styled from "@emotion/styled";

import tvlBg from "../../assets/staking/tvl-bg.svg";
import apyBg from "../../assets/staking/apy-bg.svg";
import holdersBg from "../../assets/staking/holders-bg.svg";

export const Container = styled.div`
  height: calc(100vh - 162px);
  width: 100vw;

  padding: 32px 64px;
  padding-top: 72px;
  position: relative;
  /* background-color: var(--background-dark); */
`;

export const ContentContainer = styled.div`
  width: 80%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ContentLeft = styled.div`
  display: flex;
  flex-direction: column;
  gap: 64px;
`;

export const StakingRewardsImg = styled.img`
  width: 35vw;
  aspect-ratio: 660 / 350;
  margin: auto;
`;

export const StatsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Stat = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;

  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  padding: 64px 64px;
`;

export const TvlContainer = styled(Stat)`
  /* background-image: url(${tvlBg}); */
  filter: drop-shadow(0px 10px 32px #08f684);
`;

export const ApyContainer = styled(Stat)`
  /* background-image: url(${apyBg}); */
  filter: drop-shadow(0px 10px 32px #ff9859);
`;
export const HoldersContainer = styled(Stat)`
  /* background-image: url(${holdersBg}); */
  filter: drop-shadow(0px 10px 32px #6ae4ff);
`;

const StatValue = styled.div`
  font-size: 36px;
  font-weight: 500;
  line-height: 130%;
  text-align: center;
`;

export const StatValueTvl = styled(StatValue)`
  background: linear-gradient(180deg, #00c3cf 0%, #08f684 100%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  filter: drop-shadow(0px 10px 32px #08f684);
`;

export const StatValueApy = styled(StatValue)`
  background: linear-gradient(180deg, #ffd15c 0%, #ff9859 100%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  filter: drop-shadow(0px 10px 32px #ff9859);
`;

export const StatValueHolders = styled(StatValue)`
  background: linear-gradient(180deg, #6a9fff 0%, #6ae4ff 100%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  filter: drop-shadow(0px 10px 32px #6ae4ff);
`;

export const StatLabel = styled.div`
  font-size: 28px;
  font-weight: 400;
  line-height: 130%;
  text-align: center;
  color: var(--text-secondary);
`;

export const Box = styled.div`
  padding: 24px;
  background-color: var(--background-primary);
  border-radius: 32px;
  box-sizing: border-box;
  width: 25vw;
  overflow: hidden;
  position: relative;
  z-index: 99;
  /* padding-bottom: 0; */
`;

export const Amount = styled.div`
  padding: 18px;
  border: 2px solid var(--fill-secondary);
  border-radius: 18px;
  display: flex;
  align-items: center;
  gap: 16px;
  justify-content: space-between;
`;

export const Asset = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const AssetLogo = styled.img`
  /* background: #4ca3ff; */
  height: 28px;
  width: 28px;
  border-radius: 50%;
`;

export const AssetName = styled.div`
  color: var(--text-secondary);
  font-weight: 500;
  font-size: 20px;
  line-height: 130%;
`;

export const AmountInput = styled.input`
  border: unset;
  width: fit-content;
  background-color: transparent;
  flex: 1;
  overflow: hidden;
  text-align: right;

  color: var(--text-primary);
  font-weight: 500;
  font-size: 20px;
  line-height: 130%;

  outline: unset;

  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
`;

export const Details = styled.div`
  margin: 24px 0;

  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;

  gap: 12px;
`;

export const BottomDetails = styled(Details)`
  margin: 0;
`;

export const DetailsLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const DetailsLineText = styled.div`
  font-size: 16px;
  line-height: 130%;
  font-weight: 400;
`;

export const Button = styled.button`
  background-color: var(--fill-primary);
  color: var(--text-primary);
  border-radius: 36px;
  padding: 12px 24px;
  cursor: pointer;
  font-weight: 500;
  font-size: 16px;
  line-height: 130%;
  width: 100%;

  border: unset;
`;

export const SeparatorContainer = styled.div`
  height: 20px;
  /* width: 100%; */
  padding: 0 24px;
  position: relative;
  margin: 16px -24px;
`;

export const SeparatorInner = styled.div`
  height: 10px;
  width: 100%;
  border-bottom: 2px dashed var(--fill-secondary);
`;

const SeparatorCircle = styled.div`
  height: 20px;
  width: 20px;
  border-radius: 50%;
  position: absolute;
  top: 0;
  background-color: var(--background-dark);
`;

export const SeparatorCircleLeft = styled(SeparatorCircle)`
  left: -10px;
`;
export const SeparatorCircleRight = styled(SeparatorCircle)`
  right: -10px;
`;


export const BackgroundContainer = styled.div`
  width: 250vw;
  height: 70vh;
  position: absolute;
  bottom: -162px;
  left: 0;
  overflow: hidden;
`;
